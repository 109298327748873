<template>
  <v-rating
    v-if="show"
    v-model="computedValue"
    class="d-inline-flex hover-pointer"
    :backgroundColor="backgroundColor"
    :small="small"
    :size="size"
    :color="$vuetify.theme.themes.light.accent"
    @input="input"
  />
</template>

<script>
import { DRAWERS } from '@/utils/constants';
export default {
  props: {
    value: { type: Number, default: 0 },
    order: { type: Object, default: () => {} },
    orderId: { type: String, default: '' },
    showNav: { type: Boolean, default: false },
    small: { type: Boolean, default: false },
    backgroundColor: { type: String, default: 'grey grey-lighten-1' },
    size: { type: String, default: '' }
  },
  computed: {
    computedValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    },
    show() {
      if (!this.showNav) {
        return true;
      } else if (this.order) {
        return this.order.needsReview && !this.order.isLegacyPrint;
      } else if (this.orderId) {
        return true;
      }
      return false;
    }
  },
  methods: {
    input(value) {
      if (this.showNav) {
        this.$store.dispatch('drawers/open', {
          drawerId: DRAWERS.REVIEW,
          data: {
            rating: value,
            orderId: this.orderId || this.order?.id
          }
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.v-rating {
  padding: 0 10px;
  ::v-deep button {
    padding: 0;
  }
}
</style>
