<template>
  <v-form
    ref="form"
    v-model="valid"
  >
    <v-row v-if="!candidates.length">
      <v-col
        cols="12"
        md="6"
      >
        <v-text-field
          v-model="address.firstName"
          outlined
          label="First Name"
          placeholder="First Name"
          persistent-placeholder
          :rules="[$rules.required]"
          maxlength="100"
          hide-details="auto"
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <v-text-field
          v-model="address.lastName"
          outlined
          label="Last Name"
          placeholder="Last Name"
          persistent-placeholder
          :rules="[$rules.required]"
          maxlength="100"
          hide-details="auto"
          class="white"
        />
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-model="address.company"
          outlined
          label="Company"
          placeholder="Company"
          persistent-placeholder
          maxlength="100"
          hide-details="auto"
          class="white"
        />
      </v-col>
      <v-col cols="12">
        <v-combobox
          v-model="address.line1"
          :items="addresses"
          :loading="loading_addresses"
          :search-input.sync="search"
          item-text="place_name"
          item-value="properties.name"
          hide-no-data
          outlined
          hide-details="auto"
          :filter="filterAutocomplete"
          placeholder="Type your address"
          label="Address"
          auto-select-first
          :rules="[$rules.required]"
          persistent-placeholder
          class="white"
          autocomplete="nope"
          append-icon="mdi-magnify"
          @change="selectAddress"
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <v-text-field
          v-model="address.line2"
          outlined
          label="Suite, Room, Apt#"
          placeholder="Suite, Room, Apt#"
          persistent-placeholder
          maxlength="100"
          hide-details="auto"
          class="white"
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <v-text-field
          v-model="address.city"
          outlined
          label="City"
          placeholder="City"
          persistent-placeholder
          :rules="[$rules.required]"
          maxlength="100"
          hide-details="auto"
          class="white"
        />
      </v-col>
      <v-col
        v-if="!forceUsa"
        cols="12"
      >
        <v-select
          v-model="address.countryId"
          outlined
          label="Country"
          placeholder="Country"
          persistent-placeholder
          :items="countriesToShow"
          item-text="name"
          item-value="id"
          hide-details="auto"
          :rules="[$rules.required]"
          class="white"
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <v-select
          v-model="address.stateProvinceId"
          outlined
          label="State"
          placeholder="State"
          persistent-placeholder
          :rules="[$rules.required]"
          :items="states(address.countryId)"
          :disabled="states(address.countryId).length == 0"
          hide-details="auto"
          item-text="name"
          item-value="id"
          class="white"
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <v-text-field
          v-model="address.postalCode"
          outlined
          label="Zip / Postal Code"
          placeholder="Zip / Postal Code"
          persistent-placeholder
          :rules="[$rules.required]"
          maxlength="7"
          hide-details="auto"
          class="white"
        />
      </v-col>
      <v-col
        v-if="!isAdd && !hideDefault"
        cols="12"
        md="6"
      >
        <v-select
          v-model="address.isDefault"
          :items="yesNoSelect"
          label="Default"
          placeholder="Default"
          persistent-placeholder
          item-text="value"
          item-value="key"
          outlined
          hide-details="auto"
          class="white"
        />
      </v-col>
    </v-row>
    <CandidateList
      v-else
      :address="address"
      @set="setAddress"
    />
    <Portal :to="`drawerAppend-${drawerId}`">
      <v-divider />
      <v-row class="pa-2 ma-0">
        <v-col
          v-if="!candidates.length"
          cols="12"
        >
          <mcs-btn
            block
            color="black"
            large
            :loading="validating || saving"
            @click="save"
          >
            {{ isAdd ? 'Add Address' : 'Update' }}
          </mcs-btn>
          <mcs-btn
            v-if="!isAdd"
            block
            color="error"
            large
            text
            :loading="saving"
            class="mt-2"
            @click="deleteAddress"
          >
            Delete
          </mcs-btn>
        </v-col>
      </v-row>
    </Portal>
  </v-form>
</template>

<script>
import CandidateList from '@/components/print/checkout/addresses/CandidateList';
import { DRAWERS } from '@/utils/constants';
import axios from 'axios';
import debounce from 'debounce';
import { mapGetters } from 'vuex';

export default {
  components: { CandidateList },
  props: {
    id: {
      type: [Number, String],
      default: 0
    },
    drawerId: { type: String, default: DRAWERS.PRINT_CHECKOUT_SHIPPING },
    addressGetter: { type: String, default: 'user/addresses' },
    forceUsa: { type: Boolean, default: false },
    hideDefault: { type: Boolean, default: false },
    saveAction: { type: String, default: 'user/validateAndSaveAddress' },
    deleteAction: { type: String, default: 'user/deleteAddress' }
  },
  data: () => ({
    saving: false,
    validating: false,
    valid: true,
    address: {},
    loading_addresses: false,
    addresses: [],
    search: '',
    yesNoSelect: [
      { key: true, value: 'Yes' },
      { key: false, value: 'No' }
    ]
  }),
  computed: {
    isAdd() {
      return !this.id;
    },
    countriesToShow() {
      return this.countries.filter((x) => x.isPrintable);
    },
    existing_addresses() {
      return this.$store.getters[this.addressGetter];
    },
    ...mapGetters({
      countries: 'lookups/countries',
      states: 'lookups/states',
      state_abbr: 'lookups/state_abbr',
      candidates: 'user/candidates'
    })
  },
  watch: {
    id(val, oldVal) {
      if (val !== oldVal) {
        this.initializeAddress();
      }
    },
    search(val, oldVal) {
      if (this.ignoreSearchChange) {
        this.ignoreSearchChange = false;
        return;
      }
      if (!val || val === oldVal) {
        return;
      }
      if (val.length < 3) {
        this.addresses = [];
        return;
      }
      debounce(this.searchAddresses, 500)(val, this);
    }
  },
  mounted() {
    this.initializeAddress();
  },
  methods: {
    save() {
      if (this.$refs.form.validate()) {
        this.validating = true;
        this.$store
          .dispatch(this.saveAction, this.address)
          .then((data) => {
            if (data.candidates && data.candidates.length) {
              // do nothing
            } else if (data.address) {
              this.$emit('save', data.address.id);
            }
          })
          .catch((error) => {
            this.logError(error, 'An error occurred while validating an address.');
          })
          .finally(() => {
            this.validating = false;
          });
      }
    },
    async searchAddresses(val) {
      if (!val) {
        this.addresses = [];
        this.address = {};
      }
      if (this.loading_addresses) {
        return;
      }

      this.loading_addresses = true;

      let countryString = 'US';
      if (!this.forceUsa) {
        countryString += ',CA';
      }

      const url = `https://api.mapbox.com/search/geocode/v6/forward?q=${encodeURIComponent(
        val
      )}&proximity=ip&types=address&access_token=${
        process.env.VUE_APP_MAPBOX_TOKEN
      }&country=${countryString}`;

      const list = await axios.get(url);
      this.addresses = list.data.features.map((x) =>
        Object.assign(x, { place_name: `${x.properties.name}, ${x.properties.place_formatted}` })
      );

      this.loading_addresses = false;
    },
    selectAddress(val) {
      if (!val) {
        return;
      }
      const context = val.properties.context;

      const countryId =
        this.countries.filter((x) => x.iso === context.country.country_code).map((x) => x.id)[0] ||
        226;
      const stateId = this.states(countryId)
        .filter((x) => x.abbr === context.region.region_code)
        .map((x) => x.id)[0];
      this.address = Object.assign(this.address, {
        line1: context.address.name,
        line2: '',
        city: context.place.name,
        stateProvinceId: stateId,
        postalCode: context.postcode.name,
        countryId: countryId
      });
    },
    filterAutocomplete(item, queryText, itemText) {
      return true;
    },
    setAddress(address) {
      if (address) {
        this.address.line1 = address.line1;
        this.address.line2 = address.line2;
        this.address.city = address.city;
        this.address.stateProvinceId = address.stateProvinceId;
        this.address.postalCode = address.postalCode;
        this.address.countryId = address.countryId;
        this.address.validated = true;
      } else {
        this.address.validated = false;
      }
      this.saveAddress();
    },
    saveAddress() {
      this.saving = true;
      this.$store
        .dispatch('user/saveAddress', this.address)
        .then((id) => {
          this.$emit('save', id);
        })
        .catch((error) => {
          this.logError(error, 'An error occurred while saving an address.');
        })
        .finally(() => {
          this.saving = false;
        });
    },
    initializeAddress() {
      this.address = this.existing_addresses.find((x) => x.id === this.id) || { countryId: 226 };
      if (this.forceUsa) {
        this.address.countryId = 226;
      }
    },
    async deleteAddress() {
      if (
        await this.$root.$confirm('Delete Address', 'Are you sure you want to delete this address?')
      ) {
        this.saving = true;
        this.$store
          .dispatch(this.deleteAction, this.address.id)
          .then((id) => {
            this.$emit('save');
          })
          .catch((error) => {
            this.logError(error, 'An error occurred while deleting an address.');
          })
          .finally(() => {
            this.saving = false;
          });
      }
    },
    getAddress() {
      return this.address;
    }
  }
};
</script>
