<template>
  <Drawer
    :id="drawerId"
    :title="title"
    padded
    :loading="loadingDrawerData"
  >
    <template v-if="selectedPlan.stripePlanId && !showConfirmation">
      <div>
        <template v-if="!upgradeInvoice">
          <mcs-sheet
            outlined
            class="bg-gradient-primaryLight"
          >
            <div class="d-flex align-center pt-5">
              <v-img
                src="https://res.cloudinary.com/mycreativeshop/image/upload/f_auto/v1731421392/public/freedom-sketch"
                width="120"
                class="flex-grow-0"
              />
              <div class="text-h6 ml-4">Unlock unlimited creative freedom</div>
            </div>
            <IntervalToggle />
            <v-divider class="my-5" />
            <div class="subtitle-2">Summary</div>
            <div class="d-flex justify-space-between mt-5">
              <div>Unlimited access</div>
              <div :class="{ 'text-decoration-line-through': enableFirstMonthDiscount }">
                {{ selectedPlanBasePriceFormatted }}
              </div>
            </div>
            <div
              v-if="teamUserCount"
              class="d-flex justify-space-between mt-2"
            >
              <div>
                {{ teamUserCount }} additional users @
                {{ perUserUnitPriceFormatted }}
              </div>
              <div>
                {{ perUserPriceFormatted }}
              </div>
            </div>
            <div class="d-flex justify-space-between body-1 font-weight-bold mt-3">
              <div>Total today</div>
              <div>{{ totalDueFormatted }}</div>
            </div>
            <div class="d-flex justify-end">
              <MultiCurrencyToggle />
            </div>
          </mcs-sheet>
          <mcs-alert
            class="mt-5 border-none"
            type="info"
            prependIcon="mdi-account-plus"
            boldText="Additional people can be added for FREE!"
          />
        </template>
        <mcs-sheet
          v-else
          outlined
          class="bg-gradient-primaryLight"
        >
          <div class="d-flex align-center pt-5">
            <v-img
              src="https://res.cloudinary.com/mycreativeshop/image/upload/f_auto/v1731421392/public/freedom-sketch"
              width="120"
              class="flex-grow-0"
            />
            <div class="text-h6 ml-4">Annual Plan</div>
          </div>
          <v-divider class="my-5" />
          <div class="subtitle-2">Summary</div>
          <div class="d-flex justify-space-between mt-5">
            <div>Unlimited access</div>
            <div>{{ selectedPlanBasePriceFormatted }}</div>
          </div>
          <div
            v-if="isTeamsUpgrade"
            class="d-flex justify-space-between mt-2"
          >
            <div>
              {{ subscription.teamSubscription.userCount }} additional users @
              {{ perUserUnitPriceFormatted }}
            </div>
            <div>
              {{ perUserPriceFormatted }}
            </div>
          </div>
          <div class="d-flex justify-space-between body-1 font-weight-bold mt-3">
            <div>Total today</div>
            <div>{{ upgradeInvoice.totalFormatted }}</div>
          </div>
        </mcs-sheet>
      </div>

      <div
        v-if="creditDescription"
        class="mt-5 mb-3"
      >
        <div class="font-weight-bold body-1">You are getting a credit!</div>
        <div
          class="body-2"
          v-html="creditDescription"
        />
      </div>

      <div>
        <div
          v-if="upgradeInvoice"
          class="my-3"
        >
          <PaymentSelector
            v-if="upgradeInvoice"
            @paymentMethodSelected="(id) => (paymentMethodId = id)"
          />
        </div>
        <template v-if="!upgradeInvoice">
          <PaymentEntryForm
            v-if="!upgradeInvoice"
            mode="subscription"
            :amount="totalDueInSubunit"
            showCards
            :stripePlanId="selectedPlan.stripePlanId"
            :promoCode="enableFirstMonthDiscount ? selectedPlan.firstMonthPromoCode : ''"
            :currency="selectedPlan.currency.code"
            :teamUserCount="teamUserCount"
            portalButtonTo="buttonPortal"
            :buttonText="'Place Order (' + totalDueFormatted + ')'"
            class="mt-5 mb-5"
            @success="onSuccess"
            @accountCreated="onAccountCreated($event)"
          />
        </template>

        <template v-else>
          <Portal to="buttonPortal">
            <mcs-btn
              block
              large
              color="black"
              :loading="changingPlan"
              @click="changePlan"
            >
              Change Plan
            </mcs-btn>
            <mcs-alert
              v-show="error"
              type="error"
              class="mt-3"
              :text="error"
            />
          </Portal>
        </template>
      </div>
    </template>
    <template
      v-if="selectedPlan && !showConfirmation"
      #append
    >
      <v-divider mb-3 />
      <div class="pa-5">
        <PortalTarget name="buttonPortal" />
      </div>
    </template>
    <Confirmation
      v-if="showConfirmation"
      @closeConfirmation="closeConfirmation"
    />
  </Drawer>
</template>

<script>
import { mapGetters } from 'vuex';
import Drawer from '../../components/layout/Drawer';
import { APP_EVENTS, DRAWERS } from '../../utils/constants';
import PaymentEntryForm from '../payments/PaymentEntryForm';
import PaymentSelector from '../payments/PaymentSelector';
import Confirmation from '../subscription/Confirmation';
import IntervalToggle from '../subscription/IntervalToggle';
import MultiCurrencyToggle from '../subscription/MultiCurrencyToggle';

export default {
  name: 'SubscriptionCheckoutDrawer',

  components: {
    Drawer,
    PaymentEntryForm,
    PaymentSelector,
    IntervalToggle,
    MultiCurrencyToggle,
    Confirmation
  },

  props: {
    iframed: Boolean
  },

  data() {
    return {
      paymentMethodId: 0,
      changingPlan: false,
      drawerId: DRAWERS.SUBSCRIPTION_CHECKOUT,
      showConfirmation: false,
      showPlanDetail: false,
      error: ''
    };
  },

  computed: {
    title() {
      if (this.showConfirmation) return 'Welcome to MyCreativeShop!';
      return this.iframed ? 'Upgrade Plan' : 'Checkout';
    },

    subscription() {
      return this.$store.state.subscription.item;
    },

    upgradeInvoice() {
      return this.$store.state.subscription.invoicePreview;
    },

    perUserUnitPriceFormatted() {
      return `${this.currencySymbol}${this.selectedPlan.perUserPrice.toFixed(2)}`;
    },

    perUserPrice() {
      return this.teamUserCount * this.selectedPlan.perUserPrice;
    },

    perUserPriceFormatted() {
      return `${this.currencySymbol}${this.perUserPrice.toFixed(2)}`;
    },

    currencySymbol() {
      return this.$store.state.user.plan.currencySymbol;
    },

    isTeamsUpgrade() {
      return this.subscription.teamSubscription?.subscriptionItemId;
    },

    teamUserCount() {
      const val = this.$store.state.subscription.teamUserCount - this.plan.freeTeamMembersAllowed;
      if (val < 0) {
        return 0;
      }
      return val;
    },

    creditDescription() {
      if (!this.upgradeInvoice || this.upgradeInvoice.credit <= 0) return '';
      var basePrice = this.selectedPlan.cost;
      if (this.isTeamsUpgrade) {
        basePrice += this.perUserPrice;
      }
      var s = `The base price of this plan is
      ${this.currencySymbol}${basePrice.toFixed(2)} per year. You are receiving
      a credit of ${this.upgradeInvoice.creditFormatted} for the unused days in your current plan.
      You will save ${this.upgradeInvoice.savingsFormatted} over the course of this plan as
      comapred to your current plan. You will not be charged again until
      <strong>${this.upgradeInvoice.periodEnd}</strong>,
      at which point your subscription will renew. This plan can be canceled
      at anytime but is non-refundable.`;
      return s;
    },

    loadingDrawerData() {
      return this.$store.state.subscription.invoicePreviewLoading;
    },

    ...mapGetters({
      drawerOpenedAt: 'drawers/lastOpenedAt',
      selectedPlan: 'subscription/selectedPlan',
      enableFirstMonthDiscount: 'subscription/enableFirstMonthDiscount',
      selectedPlanBasePriceFormatted: 'subscription/selectedPlanBasePriceFormatted',
      totalDue: 'subscription/totalDue',
      totalDueInSubunit: 'subscription/totalDueInSubunit',
      totalDueFormatted: 'subscription/totalDueFormatted',
      plan: 'user/plan'
    })
  },

  methods: {
    changePlan() {
      this.changingPlan = true;
      this.error = '';
      var payload = {
        planId: this.selectedPlan.planId,
        paymentMethodId: this.paymentMethodId ?? ''
      };
      this.$store
        .dispatch('subscription/changePlan', payload)
        .then(async (result) => {
          if (result.success) {
            // todo: analytics
            this.$store
              .dispatch('config/getAppData')
              .then(() => {
                this.$store.dispatch('subscription/getDetail');
              })
              .finally(() => {
                this.$store.commit('drawers/CLOSE_ALL');
                this.$root.$snackbarSuccess('Upgrade successful!');
              });
            this.$store.commit('drawers/CLOSE_ALL');
            this.$root.$snackbarSuccess('Upgrade successful!');
          } else {
            this.error = result.message;
          }
        })
        .finally(() => {
          setTimeout(() => {
            this.changingPlan = false;
          }, 4000);
        });
    },

    onSuccess() {
      // todo: analytics
      // wrap this in a timeout just to give the webhooks enough time to fire
      if (!this.iframed && !this.$store.state.user.hasSubscribed) {
        var gTagConversionData = {
          event_category: 'subscription',
          send_to: this.$store.getters['config/analytics'].conversionTrackingTag,
          value: this.totalDue,
          currency: this.selectedPlan.currency.code
        };
        console.log('gTag conversion: ', gTagConversionData);
        this.$gtag.event('conversion', gTagConversionData);
      }
      setTimeout(() => {
        this.$store
          .dispatch('config/getAppData')
          .then(() => {
            this.$store.dispatch('subscription/getDetail');
          })
          .finally(() => {
            this.showConfirmation = true;
          });
      }, 4000);
    },

    closeConfirmation() {
      this.showConfirmation = false;
      this.$store.commit('drawers/CLOSE_ALL');
    },

    help() {
      this.$eventBus.$emit(APP_EVENTS.OPEN_HELPSCOUT, { ask: true });
    }
  }
};
</script>

<style scoped></style>
