<template>
  <div>
    <v-row
      dense
      class="mt-4"
    >
      <v-col
        cols="auto"
        class="pl-3 my-auto"
      >
        <v-img
          :src="snailblastLogo"
          contain
          max-width="90"
        />
      </v-col>
      <v-col cols="auto"> | </v-col>
      <v-col cols="auto"> Easily print & mail postcards anywhere </v-col>
      <v-col
        cols="12"
        class="mt-1"
      >
        <ul>
          <li
            v-for="item in bullets"
            :key="item"
          >
            {{ item }}
          </li>
        </ul>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        v-if="!audience_selected"
        cols="12"
      >
        <v-text-field
          v-model="quantity"
          label="Quantity"
          hide-details="auto"
          outlined
          @change="getPrice"
        >
          <template #label>
            <div>
              Quantity
              <v-tooltip
                v-if="quantity"
                bottom
                color="white"
                max-width="300"
                min-width="250"
                class="black--text"
              >
                <template #activator="{ on, attrs }">
                  <v-icon
                    small
                    color="grey"
                    class="ml-1 hover-pointer"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-information
                  </v-icon>
                </template>
                <div class="body-2 black--text pt-4 d-flex">
                  <v-icon small> mdi-information </v-icon>
                  <div class="pl-2">
                    <p>
                      This estimated quantity helps you preview the price before adding your
                      audience. It represents the number of postcards you need, or in other words,
                      the number of people you want to reach.
                    </p>
                    <p>
                      This value will be replaced with the actual audience size once your audience
                      is added.
                    </p>
                  </div>
                </div>
              </v-tooltip>
            </div>
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="12">
        <v-menu
          v-if="!loading"
          v-model="date_menu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template #activator="{ on, attrs }">
            <v-text-field
              :value="formattedScheduledDate"
              outlined
              readonly
              v-bind="attrs"
              :rules="[$rules.required, lessThanMin]"
              append-icon="mdi-menu-down"
              placeholder="Select a date"
              hide-details="auto"
              label="In Mailbox Date"
              class="hover-pointer"
              persistent-placeholder
              v-on="on"
              @click:append="date_menu = true"
            >
              <template #label>
                <div>
                  In Mailbox Date
                  <v-tooltip
                    bottom
                    color="white"
                    max-width="300"
                    min-width="250"
                    class="black--text"
                  >
                    <template #activator="{ on, attrs }">
                      <v-icon
                        small
                        color="grey"
                        class="ml-1 hover-pointer"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-information
                      </v-icon>
                    </template>
                    <div class="body-2 black--text pt-4 d-flex">
                      <v-icon small> mdi-information </v-icon>
                      <div class="pl-2">
                        <p>
                          The in-mailbox date marks when your postcards are expected to arrive in
                          your recipients' mailboxes. Although USPS is responsible for final
                          delivery and doesn't guarantee precise dates, our team is focused on
                          quickly preparing and sending your postcards to ensure timely delivery.
                        </p>
                      </div>
                    </div>
                  </v-tooltip>
                </div>
              </template>
            </v-text-field>
          </template>
          <v-date-picker
            v-model="edit_item.delivery.earliestDate"
            :min="min"
            :max="max"
            :show-current="min"
            @input="date_menu = false"
            @change="changeMailboxDate"
          />
        </v-menu>
      </v-col>
      <v-col cols="12">
        <mcs-card :title="title">
          <template #title-append>
            <mcs-btn
              v-if="audience_selected"
              color="black"
              text
              @click="resetAudience"
            >
              Reset
            </mcs-btn>
            <mcs-btn
              v-if="audience_selected || previous_campaigns.length"
              color="primary"
              text
              @click="showAudience(!audience_selected)"
            >
              {{ audience_selected ? 'Edit' : previousText }}
            </mcs-btn>
          </template>
          <div>
            <template v-if="audience_items.length">
              <v-row
                v-for="(item, i) in audience_items"
                :key="i"
                dense
              >
                <v-col cols="auto">
                  <v-icon
                    :color="item.iconColor"
                    x-small
                  >
                    {{ item.icon }}
                  </v-icon>
                </v-col>
                <v-col>
                  {{ item.label }}
                  <span
                    :class="[
                      'text-decoration-underline',
                      item.showMailboxDate ? 'hover-pointer' : ''
                    ]"
                    @click="item.showMailboxDate ? showMailboxDate() : undefined"
                  >
                    {{ item.text }}
                  </span>
                </v-col>
              </v-row>
              <mcs-sheet
                v-if="showMaps"
                no-padding
                outlined
                class="mt-4 overflow-hidden"
              >
                <RoutesMap
                  v-if="is_eddm"
                  id="previewMap"
                  height="150px"
                  readonly
                />
                <ListPurchaseMap
                  v-if="is_list_purchase"
                  id="previewListPurchaseMap"
                  height="150px"
                  readonly
                />
                <div
                  v-if="is_upload"
                  class="pa-4 hover-pointer"
                  @click="showAudience(false)"
                >
                  <v-icon
                    small
                    class="mr-2"
                  >
                    mdi-file-document
                  </v-icon>
                  <span>{{ campaign.addressFileName }}</span>
                </div>
              </mcs-sheet>
            </template>
          </div>

          <template #actions>
            <mcs-btn
              v-if="!audience_items.length"
              block
              outlined
              large
              color="black"
              prepend-icon="mdi-plus-circle"
              @click="showAudience(false)"
            >
              {{ buttonText }}
            </mcs-btn>
          </template>
        </mcs-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ListPurchaseMap from '@/components/print/checkout/snailblast/listpurchase/Map';
import RoutesMap from '@/components/print/checkout/snailblast/routes/Map';
import { ANALYTICS_EVENTS, APP_EVENTS, DRAWERS } from '@/utils/constants';
import snailblast from '@/utils/mixins/snailblast';
import moment from 'moment';
import { mapGetters } from 'vuex';
export default {
  name: 'EditItemSnailblast',
  components: { RoutesMap, ListPurchaseMap },
  mixins: [snailblast],
  data: () => ({
    loading: false,
    date_menu: false,
    quantity: 200
  }),
  computed: {
    title() {
      return this.is_upload ? 'Mailing List' : 'Audience Size';
    },
    previousText() {
      return this.is_upload ? 'Previous list >' : 'Previous audience >';
    },
    buttonText() {
      return this.is_upload ? 'Add List' : 'Add Audience';
    },
    snailblastLogo() {
      return this.$store.state.config.snailblastLogo;
    },
    bullets() {
      if (this.is_eddm) {
        return [
          'Target one or more areas/routes on a map',
          'Target residential or residential + businesses',
          "We'll save your audience for future use",
          '16 Pt Cardstock Premium (High Gloss UV Front Only)',
          'Print, postage & mailing included'
        ];
      }
      if (this.is_list_purchase) {
        return [
          'Target one or more areas on a map',
          'Filter by 1000s of demographic details',
          'Reach individuals and businesses',
          "We'll save your list for future use",
          'Premium 16 Pt Cardstock (High Gloss UV Front Only)',
          'Print, postage & mailing included'
        ];
      }
      if (this.is_upload) {
        return [
          'Upload and mail to a list of addresses',
          'Merge duplicates & fix errors',
          "We'll save your list for future use",
          'Premium 16 Pt Cardstock (High Gloss UV Front Only)',
          'Print, postage & mailing included'
        ];
      }
      return [];
    },
    audience_selected() {
      return this.campaign && this.campaign.recipientCount;
    },
    audience_items() {
      if (!this.audience_selected) {
        return [];
      }

      return [
        {
          label: 'Addresses',
          text: this.$options.filters.separator(this.campaign.recipientCount),
          icon: 'mdi-check-circle',
          iconColor: 'green'
        },
        {
          label: 'Expected In-Mailboxes',
          text: this.formattedScheduledDate || 'Not set',
          icon: this.dateValid ? 'mdi-check-circle' : 'mdi-close-circle',
          iconColor: this.dateValid ? 'green' : 'error',
          showMailboxDate: !this.formattedScheduledDate
        }
      ];
    },
    min() {
      const date = moment(this.mailbox_date);
      return date.format('YYYY-MM-DD');
    },
    max() {
      return moment(this.min).add(90, 'days').format('YYYY-MM-DD');
    },
    formattedScheduledDate() {
      const date = this.edit_item.delivery?.earliestDate;
      if (!date) {
        return '';
      }
      return this.getScheduleRangeFormatted(date);
    },
    edit_item: {
      get() {
        return this.$store.getters['printcart/edit_item'];
      },
      set(val) {
        this.$store.dispatch('printcart/setEditItem', val);
      }
    },
    campaign: {
      get() {
        return this.$store.getters['printcart/campaign'];
      },
      set(val) {
        this.$store.dispatch('printcart/setCampaign', val);
      }
    },
    dateValid() {
      return moment(this.edit_item.delivery.earliestDate) >= moment(this.min);
    },
    showMaps() {
      return this.top_drawer.id === DRAWERS.PRINT_CHECKOUT;
    },
    ...mapGetters({
      previous_campaigns: 'snailblast/campaign/previous',
      mailbox_date: 'snailblast/campaign/mailbox_date',
      is_eddm: 'snailblast/campaign/is_eddm',
      is_upload: 'snailblast/campaign/is_upload',
      is_list_purchase: 'snailblast/campaign/is_list_purchase',
      campaign_routes: 'snailblast/campaign/routes',
      top_drawer: 'drawers/topDrawer',
      analytics_data: 'snailblast/campaign/analytics_data'
    })
  },
  watch: {
    campaign(val, oldVal) {
      if (val) {
        if (val.id !== oldVal.id && val.recipientCount) {
          this.loadData();
        } else if (val.typeId !== oldVal?.typeId) {
          this.getMailboxDate();
        }
      }
    }
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      if (!this.campaign?.typeId) {
        return;
      }
      this.loading = true;
      const calls = [
        this.$store.dispatch('snailblast/campaign/getMailboxDate', this.campaign.typeId),
        this.$store.dispatch('snailblast/lookup/get'),
        this.$store.dispatch('snailblast/campaign/setWithExtraData', this.campaign),
        this.$store.dispatch('snailblast/campaign/getPreviousCampaigns', this.campaign.typeId)
      ];
      Promise.all(calls)
        .then(() => {
          this.$store.dispatch('snailblast/routes/setRoutes', this.campaign_routes);
          this.$store.dispatch('snailblast/routes/selectRoutesFromTable', this.campaign_routes);
          this.$nextTick(() => {
            this.getPrice(this.campaign.recipientCount || this.quantity);
          });
        })
        .catch((error) => {
          this.logError(error, 'Error getting campaign information');
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getMailboxDate() {
      this.loading = true;
      Promise.all([
        this.$store.dispatch('snailblast/campaign/getMailboxDate', this.campaign.typeId),
        this.$store.dispatch('snailblast/campaign/getPreviousCampaigns', this.campaign.typeId)
      ])
        .catch((error) => {
          this.logError(error, 'Error getting mailbox date');
        })
        .finally(() => {
          this.loading = false;
        });
    },
    showAudience(previous) {
      this.$store.commit('drawers/SET_DATA', {
        drawerId: DRAWERS.SNAILBLAST_AUDIENCE,
        data: { previous }
      });
      this.$store.commit('drawers/OPEN_DRAWER', DRAWERS.SNAILBLAST_AUDIENCE);
    },
    resetAudience() {
      this.loading = true;
      this.$store
        .dispatch('printcart/setCampaign', {
          typeId: this.campaign.typeId
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getPrice(val) {
      this.$nextTick(() => {
        this.$eventBus.$emit(APP_EVENTS.GET_EDIT_ITEM_PRICE, {
          qtyOverride: val,
          source: 'snailblast'
        });
      });
    },
    lessThanMin(v) {
      return this.dateValid || `Must be on or after ${this.$options.filters.normalDate(this.min)}`;
    },
    changeMailboxDate(val) {
      this.$mixpanel.trackEvent(
        ANALYTICS_EVENTS.MAILING_SERVICE.NAME,
        null,
        Object.assign(this.analytics_data, { mailbox_date: val })
      );
    },
    showMailboxDate() {
      this.date_menu = true;
    }
  }
};
</script>
