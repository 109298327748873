import { cloneDeep } from 'lodash';

const state = {
  list: [],
  tool: {},
  qr_codes: [],
  qr_code: {}
};

const getters = {
  list(state) {
    return state.list || [];
  },
  tool(state) {
    return state.tool || {};
  },
  qr_codes(state) {
    return state.qr_codes || [];
  },
  qr_code(state) {
    return state.qr_code || {};
  }
};

const mutations = {
  SET_LIST(state, list) {
    state.list = list;
  },
  SET_TOOL(state, item) {
    state.tool = cloneDeep(item);
    const index = state.list.findIndex((x) => x.id === item.id);
    if (index >= 0) {
      this._vm.$set(state.list, index, cloneDeep(item));
    } else {
      state.list.push(cloneDeep(item));
    }
  },
  SET_QR_CODES(state, list) {
    state.qr_codes = list;
  },
  SET_QR_CODE(state, item) {
    state.qr_code = cloneDeep(item);
    const index = state.qr_codes.findIndex((x) => x.id === item.id);
    if (index >= 0) {
      this._vm.$set(state.qr_codes, index, cloneDeep(item));
    } else {
      state.qr_codes.push(cloneDeep(item));
    }
  },
  DELETE_QR_CODE(state, id) {
    const index = state.qr_codes.findIndex((x) => x.id === id);
    if (index >= 0) {
      state.qr_codes.splice(index, 1);
    }
  }
};

const actions = {
  async getList({ commit }) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .get('tools/getList')
        .then((response) => {
          commit('SET_LIST', response.data);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async get({ commit }, slug) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .get(`tools/get?slug=${slug}`)
        .then((response) => {
          commit('SET_TOOL', response.data);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async convertFile({ commit }, req) {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('file', req.file);
      formData.append('payload', JSON.stringify(req.payload));
      this._vm.$api
        .post('tools/convertFile', formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async getQrCodes({ commit }) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .get('tools/getQrCodes')
        .then((response) => {
          commit('SET_QR_CODES', response.data);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async getQrCode({ commit }, id) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .get(`tools/getQrCode?id=${id}`)
        .then((response) => {
          commit('SET_QR_CODE', response.data);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async getNewQRCodeSlug({ commit }) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .get('tools/getNewQRCodeSlug')
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async addQrCode({ commit }, req) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .post('tools/addQRCode', req)
        .then((response) => {
          commit('SET_QR_CODE', response.data);
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async updateQrCode({ commit }, req) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .post('tools/updateQRCode', req)
        .then((response) => {
          commit('SET_QR_CODE', response.data);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async deleteQrCode({ commit }, id) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .delete(`tools/deleteQRCode?id=${id}`)
        .then((response) => {
          commit('DELETE_QR_CODE', id);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
