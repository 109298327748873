<template>
  <v-btn
    :disabled="disabled"
    :loading="loading"
    :small="small"
    :large="large"
    :block="block"
    :text="text"
    :color="color"
    :outlined="outlined"
    :icon="icon"
    elevation="0"
    :rounded="!icon || rounded"
    :style="{ padding: '0 ' + horizontalPadding }"
    :to="to"
    :href="href"
    :target="target"
    @click="$emit('click', $event)"
    @mouseover="$emit('mouseover', $event)"
  >
    <div
      :class="[textColorClass]"
      class="d-flex align-center"
    >
      <v-icon
        v-if="prependIcon"
        :class="icon ? '' : 'mr-2'"
        :size="iconSizeToUse"
      >
        {{ prependIcon }}
      </v-icon>
      <span :style="{ 'font-size': fontSize }"><slot /></span>
      <v-icon
        v-if="appendIcon"
        class="ml-2"
        :size="iconSizeToUse"
      >
        {{ appendIcon }}
      </v-icon>
    </div>
  </v-btn>
</template>

<script>
import { COLORS } from '../../utils/constants';
import getForegroundColor from '../../utils/getForegroundColor';

export default {
  name: 'McsBtn',

  props: {
    disabled: Boolean,
    loading: Boolean,
    small: Boolean,
    large: Boolean,
    color: { type: String, default: 'primary' },
    block: Boolean,
    text: Boolean,
    rounded: Boolean,
    icon: Boolean,
    iconSize: { type: Number, default: 0 },
    to: { type: [String, Object], default: '' },
    href: { type: String, default: '' },
    target: { type: String, default: '' },
    outlined: Boolean,
    prependIcon: { type: String, default: '' },
    appendIcon: { type: String, default: '' }
  },

  data() {
    return {};
  },

  computed: {
    fontSize() {
      if (this.small) return '12px';
      if (this.large) return '14px';
      return '13px';
    },

    iconSizeToUse() {
      if (this.iconSize) return this.iconSize;
      if (this.icon) {
        if (this.small) return 20;
        if (this.large) return 28;
        return 24;
      }
      if (this.small) return 14;
      if (this.large) return 20;
      return 18;
    },

    horizontalPadding() {
      if (this.icon) return '0';
      if (this.block) return '5px';
      if (this.small) return '23px';
      if (this.large) return '45px';
      return '28px';
    },

    textColorClass() {
      var colorName = '';
      if (this.color === COLORS.GREY.NAME) {
        if (this.icon || this.outlined) {
          colorName = COLORS.BLACK.NAME;
        }
      } else if (this.text || this.icon || this.outlined) {
        colorName = '';
      } else {
        colorName = getForegroundColor(this.color).NAME;
      }
      return colorName ? `${colorName}--text` : '';
    }
  }
};
</script>

<style>
.v-btn.black .v-btn__loader {
  color: white;
}
</style>
