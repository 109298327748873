import { cloneDeep } from 'lodash';

const state = {
  list: [],
  templates: [],
  folders: [],
  images: [],
  colors: [],
  fonts: [],
  selected: []
};

const getters = {
  list(state) {
    return state.list || [];
  },
  collection_name(state, getters) {
    return (id) => {
      return getters.list.filter((x) => x.id === id).map((x) => x.name)[0] || '';
    };
  },

  all_templates(state) {
    return state.templates || [];
  },
  templates(state, getters) {
    return (id) => {
      return getters.all_templates.filter((x) => x.folderId === id);
    };
  },
  all_folders(state) {
    return state.folders || [];
  },
  folders(state, getters) {
    return (id) => {
      return getters.all_folders
        .filter((x) => x.parentId === id)
        .map((x) =>
          Object.assign(x, {
            folderCount: getters.all_folders.filter((y) => y.parentId === x.id).length,
            templateCount: getters.all_templates.filter((y) => y.folderId === x.id).length,
            imageCount: getters.all_images.filter((y) => y.folderId === x.id).length
          })
        );
    };
  },
  parents(state) {
    return (id) => {
      const parents = [];
      let current = state.folders.find((x) => x.id === id);
      if (current) {
        do {
          parents.splice(0, 0, current);
          current = state.folders.find((x) => x.id === current.parentId);
        } while (current);
      }
      return parents;
    };
  },
  all_images(state) {
    return state.images || [];
  },
  images(state, getters) {
    return (id) => {
      return getters.all_images.filter((x) => x.folderId === id);
    };
  },
  colors(state) {
    return state.colors || [];
  },
  fonts(state) {
    return state.fonts || [];
  },

  selected(state) {
    return state.selected || [];
  },

  hideCollectionDesigns(state, getters, rootState, rootGetters) {
    return rootGetters['user/isSprayNet'];
  }
};

const mutations = {
  SET_LIST(state, list) {
    state.list = list;
  },
  SET_COLLECTION(state, item) {
    const index = state.list.findIndex((x) => x.id === item.id);
    if (index >= 0) {
      this._vm.$set(state.list, index, cloneDeep(item));
    } else {
      state.list.push(cloneDeep(item));
    }
  },
  DELETE_USER(state, req) {
    const index = state.list.findIndex((x) => x.id === req.collectionId);
    if (index >= 0) {
      const userIndex = state.list[index].users.findIndex((x) => x.id === req.id);
      if (userIndex >= 0) {
        state.list[index].splice(userIndex, 1);
      }
    }
  },
  DELETE_COLLECTION(state, id) {
    const index = state.list.findIndex((x) => x.id === id);
    if (index >= 0) {
      state.list.splice(index, 1);
    }
  },

  SET_TEMPLATES(state, item) {
    state.selected = [];
    state.images = [];
    state.templates = item.templates;
    state.folders = item.folders;
  },
  SET_TEMPLATE(state, item) {
    const index = state.templates.findIndex((x) => x.id === item.id);
    if (index >= 0) {
      if (item.name) {
        this._vm.$set(state.templates[index], 'name', item.name);
      }
      this._vm.$set(state.templates[index], 'folderId', item.folderId);
      this._vm.$set(state.templates[index], 'publishedInCollection', item.publishedInCollection);
    } else {
      state.templates.push(item);
    }
  },
  DELETE_TEMPLATE(state, id) {
    const index = state.templates.findIndex((x) => x.id === id);
    if (index >= 0) {
      state.templates.splice(index, 1);
    }
  },
  SET_FOLDER(state, item) {
    const index = state.folders.findIndex((x) => x.id === item.id);
    if (index >= 0) {
      this._vm.$set(state.folders, index, item);
    } else {
      state.folders.push(item);
    }
  },
  DELETE_FOLDER(state, id) {
    const index = state.folders.findIndex((x) => x.id === id);
    if (index >= 0) {
      state.folders.splice(index, 1);
    }
  },

  SET_IMAGES(state, item) {
    state.selected = [];
    state.images = item.images;
    state.templates = [];
    state.folders = item.folders;
  },
  SET_IMAGE(state, item) {
    const index = state.images.findIndex((x) => x.id === item.id);
    if (index >= 0) {
      this._vm.$set(state.images[index], 'folderId', item.folderId);
    } else {
      state.images.push(item);
    }
  },
  DELETE_IMAGE(state, id) {
    const index = state.images.findIndex((x) => x.id === id);
    if (index >= 0) {
      state.images.splice(index, 1);
    }
  },

  SET_COLORS(state, item) {
    state.colors = item.colors;
  },
  ADD_COLOR(state, item) {
    state.colors.push(item);
  },
  DELETE_COLOR(state, id) {
    const index = state.colors.findIndex((x) => x.id === id);
    if (index >= 0) {
      state.colors.splice(index, 1);
    }
  },

  SET_FONTS(state, list) {
    state.fonts = list || [];
  },
  ADD_FONT(state, item) {
    state.fonts.push(item);
  },
  DELETE_FONT(state, id) {
    const index = state.fonts.findIndex((x) => x.id === id);
    if (index >= 0) {
      state.fonts.splice(index, 1);
    }
  },

  SET_SELECTION(state, id) {
    const index = state.selected.findIndex((x) => x === id);
    if (index >= 0) {
      state.selected.splice(index, 1);
    } else {
      state.selected.push(id);
    }
  },
  SET_SELECTED(state, list) {
    state.selected = list;
  },
  CLEAR_SELECTED(state) {
    state.selected = [];
  }
};

const actions = {
  async getList({ commit }) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .get('collections/getList')
        .then((response) => {
          commit('SET_LIST', response.data);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async get({ commit }, id) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .get(`collections/get?id=${id}`)
        .then((response) => {
          commit('SET_COLLECTION', response.data);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async save({ commit }, item) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .post('collections/save', item)
        .then((response) => {
          commit('SET_COLLECTION', response.data);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async delete({ commit }, id) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .delete(`collections/delete?id=${id}`)
        .then((response) => {
          commit('DELETE_COLLECTION', id);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  async getTemplates({ commit }, collectionId) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .get(`collections/getTemplates?collectionId=${collectionId}`)
        .then((response) => {
          commit('SET_TEMPLATES', response.data);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async saveTemplateFolder({ commit }, folder) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .post('collections/saveTemplateFolder', folder)
        .then((response) => {
          commit('SET_FOLDER', response.data);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async deleteTemplateFolder({ commit }, req) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .delete(`collections/deleteTemplateFolder?id=${req.id}&collectionId=${req.collectionId}`)
        .then((response) => {
          commit('DELETE_FOLDER', req.id);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async saveTemplate({ commit }, req) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .post('collections/saveTemplate', req)
        .then((response) => {
          commit('SET_TEMPLATE', req);
          commit('CLEAR_SELECTED');
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async saveTemplateCollection({ commit }, req) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .post('collections/saveTemplate', req)
        .then((response) => {
          commit('DELETE_TEMPLATE', req.id);
          commit('CLEAR_SELECTED');
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async moveTemplate({ commit }, req) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .put(`collections/moveTemplate?id=${req.id}&folderId=${req.folderId || ''}&collectionId=${req.collectionId}`)
        .then((response) => {
          commit('SET_TEMPLATE', response.data);
          commit('CLEAR_SELECTED');
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async duplicateTemplate({ commit }, req) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .put(`collections/duplicateTemplate?id=${req.id}&collectionId=${req.collectionId}`)
        .then((response) => {
          commit('SET_TEMPLATE', response.data);
          commit('CLEAR_SELECTED');
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  async getImages({ commit }, collectionId) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .get(`collections/getImages?collectionId=${collectionId}`)
        .then((response) => {
          commit('SET_IMAGES', response.data);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async saveImageFolder({ commit }, folder) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .post('collections/saveImageFolder', folder)
        .then((response) => {
          commit('SET_FOLDER', response.data);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async deleteImageFolder({ commit }, req) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .delete(`collections/deleteImageFolder?id=${req.id}&collectionId=${req.collectionId}`)
        .then((response) => {
          commit('DELETE_FOLDER', req.id);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async addImages({ commit, dispatch }, container) {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      for (var i = 0; i < container.files.length; i++) {
        formData.append('file', container.files[i]);
      }
      this._vm.$api
        .post(
          `collections/addImages?folderId=${container.folderId}&collectionId=${container.collectionId}`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        )
        .then((response) => {
          response.data.forEach((x) => {
            commit('SET_IMAGE', x);
          });
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async saveImageCollection({ commit }, req) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .post('collections/saveImage', req)
        .then((response) => {
          commit('DELETE_IMAGE', req.id);
          commit('CLEAR_SELECTED');
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async moveImage({ commit }, req) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .put(`collections/moveImage?id=${req.id}&folderId=${req.folderId || ''}&collectionId=${req.collectionId}`)
        .then((response) => {
          commit('SET_IMAGE', response.data);
          commit('CLEAR_SELECTED');
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async deleteImage({ commit }, req) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .delete(`collections/deleteImage?id=${req.id}&collectionId=${req.collectionId}`)
        .then((response) => {
          commit('DELETE_IMAGE', req.id);
          commit('CLEAR_SELECTED');
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  async getColors({ commit }, collectionId) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .get(`collections/getColors?collectionId=${collectionId}`)
        .then((response) => {
          commit('SET_COLORS', response.data);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async addColor({ commit }, color) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .post('collections/addColor', color)
        .then((response) => {
          commit('ADD_COLOR', response.data);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async deleteColor({ commit }, req) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .delete(`collections/deleteColor?id=${req.id}&collectionId=${req.collectionId}`)
        .then((response) => {
          commit('DELETE_COLOR', req.id);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  async getFonts({ commit }, collectionId) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .get(`collections/getFonts?collectionId=${collectionId}`)
        .then((response) => {
          commit('SET_FONTS', response.data);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async addFont({ commit }, req) {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      for (var file of req.files) {
        formData.append('file', file);
      }
      formData.append('payload', JSON.stringify(req.font));
      this._vm.$api
        .post('collections/addFont', formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        })
        .then((response) => {
          commit('SET_FONTS', response.data);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async deleteFont({ commit }, req) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .delete(`collections/deleteFont?id=${req.id}&collectionId=${req.collectionId}`)
        .then((response) => {
          commit('DELETE_FONT', req.id);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  select({ commit }, id) {
    commit('SET_SELECTION', id);
  },
  setSelected({ commit }, ids) {
    commit('SET_SELECTED', ids);
  },
  clearSelected({ commit }) {
    commit('CLEAR_SELECTED');
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
