import { values } from 'lodash';
import { DRAWERS } from '../../utils/constants';

const drawers = [];
for (var i = 0; i < values(DRAWERS).length; i++) {
  drawers.push({ id: values(DRAWERS)[i], zIndex: i, open: false });
}

const state = {
  items: drawers,
  lastOpenedId: null,
  lastOpenedAt: null,
  data: {}
};

const getters = {
  topDrawer(state) {
    return state.items.filter((x) => x.open).sort(({ zIndex: a }, { zIndex: b }) => b - a)[0] || {};
  },

  enableBack(state) {
    return state.items.filter((x) => x.open).length > 1;
  },

  lastOpenedAt(state) {
    return state.lastOpenedAt;
  },

  data: (state) => (drawerId) => {
    return state.data[drawerId] || {};
  }
};

const actions = {
  open({ commit }, req) {
    return new Promise((resolve, reject) => {
      commit('SET_DATA', {
        drawerId: req.drawerId,
        data: req.data || {}
      });
      commit('OPEN_DRAWER', req.drawerId);
      resolve();
    });
  }
};

const mutations = {
  OPEN_DRAWER(state, drawerId) {
    var openDrawers = state.items.filter((x) => x.open);
    state.lastOpenedAt = Date.now();
    if (openDrawers.find((x) => x.id === drawerId)) return;

    var curMaxIndex = Math.max(...openDrawers.map((x) => x.zIndex));
    curMaxIndex = Math.max(curMaxIndex, 100);
    var index = state.items.findIndex((x) => x.id === drawerId);
    state.items[index].open = true;
    state.items[index].zIndex = curMaxIndex + 1;
    state.lastOpenedId = state.items[index].id;
  },

  SET_DATA(state, payload) {
    state.data[payload.drawerId] = payload.data;
  },

  CLEAR_DATA(state, drawerId) {
    if (state.data[drawerId]) state.data[drawerId] = null;
  },

  CLOSE_DRAWER(state, drawerId) {
    var index = state.items.findIndex((x) => x.id === drawerId);
    if (state.data[drawerId]) state.data[drawerId] = null;
    state.items[index].open = false;
  },

  CLOSE_ALL(state) {
    state.items.forEach((x) => {
      x.open = false;
    });
  },

  GO_BACK(state) {
    var topDrawer = state.items
      .filter((x) => x.open)
      .sort(({ zIndex: a }, { zIndex: b }) => b - a)[0];

    var index = state.items.findIndex((x) => x.id === topDrawer.id);
    state.items[index].open = false;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
