<template>
  <mcs-card
    :transparent="transparent"
    :title="title"
    :subtitle="subtitle"
    :titleSizeClass="titleSizeClass"
    @click="$emit('click', $event)"
  >
    <template #header>
      <slot name="header"></slot>
      <v-sheet
        v-if="!thumbnail && url"
        :class="[transparent ? '' : imageBackgroundColor, 'pa-2', 'mb-4']"
      >
        <v-img
          :width="imgWidth"
          :height="imgHeight"
          :max-width="imgMaxWidth"
          :max-height="imgMaxHeight"
          :contain="imgContain"
          :src="url"
        />
      </v-sheet>
    </template>
    <template #prepend>
      <v-sheet
        v-if="thumbnail && url"
        :class="[transparent ? '' : imageBackgroundColor, 'pa-2']"
      >
        <v-img
          :width="imgWidth"
          :height="imgHeight"
          :max-width="imgMaxWidth"
          :max-height="imgMaxHeight"
          :contain="imgContain"
          :src="url"
        />
      </v-sheet>
    </template>

    <slot></slot>
    <template #actions>
      <slot name="actions"></slot>
    </template>
    <template #append>
      <slot name="append"></slot>
    </template>
  </mcs-card>
</template>

<script>
export default {
  name: 'McsImageCard',

  props: {
    title: { type: String, default: '' },
    subtitle: { type: String, default: '' },
    url: { type: String, default: '' },
    imgWidth: { type: String, default: '' },
    imgHeight: { type: String, default: '' },
    imgMaxWidth: { type: String, default: '' },
    imgMaxHeight: { type: String, default: '' },
    imgContain: { type: Boolean, default: false },
    transparent: { type: Boolean, default: false },
    imageBackgroundColor: { type: String, default: 'greyLight' },
    thumbnail: { type: Boolean, default: false },
    titleSizeClass: { type: String, default: 'subtitle-1' }
  },

  data() {
    return {};
  },

  computed: {}
};
</script>
