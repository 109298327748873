import { cloneDeep } from 'lodash';

const state = {
  list: []
};

const getters = {
  list(state) {
    return state.list || [];
  }
};

const mutations = {
  SET_LIST(state, list) {
    state.list = list;
  },
  SET_COMPANY(state, item) {
    const index = state.list.findIndex((x) => x.id === item.id);
    if (index >= 0) {
      this._vm.$set(state.list, index, cloneDeep(item));
    } else {
      state.list.push(cloneDeep(item));
    }
  }

};

const actions = {
  async getList({ commit }) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .get('companies/getList')
        .then((response) => {
          commit('SET_LIST', response.data);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async save({ commit }, item) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .post('companies/save', item)
        .then((response) => {
          commit('SET_COMPANY', response.data);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
