<template>
  <div>
    <mcs-card
      :loading="saving"
      :class="[
        'bg-greyLight',
        !dragover ? 'border-dashed' : '',
        'hover-pointer',
        'border-thick',
        cardClass
      ]"
      @click="showFileSelect"
      @drop.native.prevent="onDrop($event)"
      @dragover.native.prevent="dragover = true"
      @dragenter.native.prevent="dragover = true"
      @dragleave.native.prevent="dragover = false"
    >
      <v-row
        class="d-flex flex-column text-center py-8"
        dense
        align="center"
        justify="center"
      >
        <v-col cols="12">
          <v-icon
            large
            color="primary"
          >
            mdi-cloud-upload
          </v-icon>
        </v-col>
        <v-col
          cols="12"
          class="body-1 font-weight-bold"
        >
          {{ cta }}
        </v-col>
        <v-col
          cols="12"
          class="body-2"
        >
          {{ formatCta }}
        </v-col>
      </v-row>
      <input
        ref="hiddenFileUpload"
        type="file"
        :multiple="multiple"
        style="display: none"
        :accept="accept"
        @change="clickFileSelected"
      />
    </mcs-card>
    <div
      v-if="error"
      class="error--text mt-2"
    >
      {{ error }}
    </div>
    <div
      v-else-if="files.length && !hideFiles"
      class="mt-6"
    >
      <div class="subtitle-2 mb-2 font-weight-bold">Files</div>
      <v-row
        v-for="(file, i) in files"
        :key="i"
        dense
      >
        <v-col>
          {{ file.name }}
        </v-col>
        <v-spacer class="spacer-tight" />
        <v-col cols="auto">
          <v-icon
            color="black"
            small
            class="ml-auto"
            @click="remove(i)"
          >
            mdi-close-circle
          </v-icon>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: { type: Array, default: () => {} },
    saving: { type: Boolean, default: false },
    error: { type: String, default: '' },
    accept: { type: String, default: '' },
    cta: { type: String, default: '' },
    formatCta: { type: String, default: '' },
    multiple: { type: Boolean, default: false },
    hideFiles: { type: Boolean, default: false },
    cardClass: { type: String, default: '' }
  },
  data: () => ({
    dragover: false
  }),
  computed: {
    files: {
      get() {
        return this.value || [];
      },
      set(val) {
        if (val) {
          this.$emit('input', val);
        }
      }
    }
  },
  methods: {
    showFileSelect() {
      // this.files = [];
      this.$refs.hiddenFileUpload.click();
    },
    clickFileSelected(e) {
      this.setFiles(e.target.files);
    },
    onDrop(e) {
      this.dragover = false;
      // If there are already uploaded files remove them
      // if (this.files.length > 0) {
      //   this.files = [];
      // }
      // Add each file to the array of uploaded files
      this.setFiles(e.dataTransfer.files);
    },
    setFiles(files) {
      if (files) {
        Array.from(files).forEach((element) => this.files.push(element));
        this.$emit('set');
      }
    },
    remove(index) {
      this.files.splice(index, 1);
    }
  }
};
</script>
