<template>
  <mcs-card
    :class="color"
    title=" "
    :outlined="false"
    :subtitle="subtitle"
    titleSizeClass="body-1 font-weight-bold"
    subtitleSizeClass="body-2"
  >
    <template #title>
      <v-icon
        v-if="prependIcon"
        class="mr-1"
        small
      >
        {{ prependIcon }}
      </v-icon>
      {{ title }}
    </template>
    <slot></slot>
  </mcs-card>
</template>

<script>
export default {
  props: {
    title: { type: String, default: '' },
    subtitle: { type: String, default: '' },
    prependIcon: { type: String, default: '' },
    color: { type: String, default: 'greyLight' }
  },
  computed: {}
};
</script>
