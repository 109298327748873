<template>
  <mcs-btn
    v-if="show"
    color="black"
    outlined
    class="mr-3 px-4 bg-white"
    prependIcon="mdi-account-group"
    @click="click"
  >
    Invite
  </mcs-btn>
</template>

<script>
import { DRAWERS, ROUTES } from '@/utils/constants';
import { mapGetters } from 'vuex';

export default {
  name: 'InviteButton',

  data() {
    return {};
  },

  computed: {
    show() {
      if (this.$route.name === ROUTES.PROJECT_DETAIL.NAME) {
        return false;
      }
      if (this.enableApi && !this.isMCS) {
        return false;
      }
      if (this.isMasterAccountOwner) {
        return true;
      }
      if (this.collections_to_manage.length) {
        return true;
      }
      if (this.isMCS && !this.collections.length) {
        return true;
      }
      return false;
    },
    ...mapGetters({
      isMasterAccountOwner: 'user/isMasterAccountOwner',
      collections_to_manage: 'user/collections_to_manage',
      collections: 'user/collections',
      isMCS: 'user/isMCS',
      enableApi: 'user/enableApi',
      plan: 'user/plan'
    })
  },

  methods: {
    click() {
      if (this.isMasterAccountOwner || this.collections_to_manage.length) {
        this.$store.commit('drawers/OPEN_DRAWER', DRAWERS.ADD_PEOPLE);
      } else if (!this.plan.isTrial) {
        this.$store.commit('drawers/SET_DATA', {
          drawerId: DRAWERS.ADD_PEOPLE,
          data: { createCollection: true }
        });
        this.$store.commit('drawers/OPEN_DRAWER', DRAWERS.ADD_PEOPLE);
      } else {
        this.$router.push(ROUTES.SUBSCRIPTION.PATH);
      }
    }
  }
};
</script>

<style></style>
