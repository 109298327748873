<template>
  <div class="d-flex-hide flex-column justify-space-between h-full">
    <div class="pa-4">
      <!-- Cart item & price summary -->
      <DesignReview class="px-4 mb-4" />
      <mcs-card
        title="Order Summary"
        no-padding
        titleSizeClass="subtitle-2"
        class="d-flex flex-column justify-space-between"
      >
        <template #title-append>
          <span
            class="hover-pointer font-weight-medium ml-3 primary--text"
            @click="$emit('cart')"
          >
            View details
          </span>
        </template>
        <div>
          <div class="mt-4 d-flex justify-space-between">
            <div>
              {{ cart_lines.length }} print item<template v-if="cart_lines.length > 1">s</template>
            </div>
            <span>{{ cart.subTotalPrice | currency }}</span>
          </div>
          <div
            v-if="appliedPromoCode"
            class="mt-1 d-flex justify-space-between"
          >
            <span>Discount ({{ appliedPromoCode }})</span>
            <span>-{{ cart.discount | currency }}</span>
          </div>
          <div
            v-if="isMissionPrint || cart.totalSalesTax > 0"
            class="mt-1 d-flex justify-space-between"
          >
            <span>
              Tax
              <span
                v-if="isMissionPrint && !isTaxExempt && cart.totalSalesTax > 0"
                class="ml-2 font-italic caption hover-pointer"
                @click="taxExempt"
                >Are you exempt?</span
              >
            </span>
            <span>{{ cart.totalSalesTax | currency }}</span>
          </div>
          <div class="mt-1 d-flex justify-space-between font-weight-bold">
            <span>Total</span>
            <span>{{ cart.totalPrice | currency }}</span>
          </div>
        </div>

        <!-- Promo code entry -->
        <div class="mt-4">
          <div class="d-flex align-center">
            <v-text-field
              v-model="cart.promoCode"
              persistent-placeholder
              placeholder="Enter code"
              label="Promo Code"
              hide-details="auto"
              outlined
            >
              <template #append="">
                <mcs-btn
                  small
                  color="grey"
                  :class="canApplyPromoCode ? 'px-5' : 'px-2'"
                  :loading="refreshingCart"
                  v-on="canApplyPromoCode ? { click: refreshCart } : {}"
                >
                  <template v-if="canApplyPromoCode"> Apply </template>
                  <template v-else>
                    <v-icon color="success"> mdi-check </v-icon>
                    Applied
                  </template>
                </mcs-btn>
              </template>
            </v-text-field>
          </div>
          <div v-if="applyPromoCodeError">
            <v-divider />
            <div class="px-4 my-2 error--text font-weight-medium">
              {{ applyPromoCodeError }}
            </div>
            <v-divider />
          </div>
        </div>

        <NonProfitRequired v-if="requireNonProfitInfo" />
      </mcs-card>

      <div>
        <PaymentSelector
          class="my-5"
          :bottomDrawer="true"
          @paymentMethodSelected="(id) => (cardId = id)"
        />
      </div>

      <mcs-sheet
        v-if="campaigns.length"
        outlined
        class="d-flex flex-column justify-space-between"
      >
        <div class="font-weight-bold mb-4 d-flex justify-space-between">
          Billing Address
          <mcs-btn
            text
            small
            color="primary"
            @click="manageAddresses"
          >
            Manage addresses
          </mcs-btn>
        </div>
        <div>
          <v-select
            v-model="addressId"
            label="Billing Address"
            outlined
            persistent-placeholder
            placeholder="Billing Address"
            :items="addressItems"
            item-text="display"
            item-value="id"
            hide-details="auto"
            :loading="validating"
            @change="changeAddress"
          />
        </div>
      </mcs-sheet>

      <!-- Promo text-->
      <IconList
        :items="promoText"
        iconColor="success"
        classes="mb-3 body-2"
        class="mt-3"
      />
    </div>

    <!-- Place order button -->
    <Portal :to="`drawerAppend-${drawerId}`">
      <v-divider />
      <v-row class="flex-grow-0 pa-2 ma-0 light">
        <v-col cols="12">
          <mcs-btn
            :loading="saving"
            large
            block
            color="black"
            :disabled="disablePlaceOrder"
            @click="placeOrder"
          >
            Place Order <span class="ml-2">({{ cart.totalPrice | currency }})</span>
          </mcs-btn>
        </v-col>
        <!-- <v-col class="my-auto">
          <div class="d-flex align-center font-weight-bold">
            <div
              v-if="cart.subTotalPriceOriginal > cart.totalPrice"
              class="text-decoration-line-through mr-2"
            >
              {{ cart.subTotalPriceOriginal | currency }}
            </div>
            <div :class="{'success--text' : cart.subTotalPriceOriginal > cart.totalPrice}">
              {{ cart.totalPrice | currency }}
            </div>
          </div>
        </v-col> -->
      </v-row>
    </Portal>
  </div>
</template>

<script>
import { ANALYTICS_EVENTS, DRAWERS } from '@/utils/constants';
import { mapGetters } from 'vuex';
import PaymentSelector from '../../../components/payments/PaymentSelector';
import IconList from '../../../components/utilities/IconList';
import NonProfitRequired from '../../print/checkout/NonProfitRequired';
import DesignReview from './DesignReview';

export default {
  name: 'PrintCheckout',

  components: {
    PaymentSelector,
    IconList,
    DesignReview,
    NonProfitRequired
  },

  props: {
    drawerId: {
      type: String,
      default: ''
    }
  },

  data: () => ({
    error: '',
    stripe: null,
    elements: null,
    card: null,
    cardId: null,
    saving: false,
    addressId: null,
    appliedPromoCode: '',
    couponApplied: false,
    refreshingCart: false,
    applyPromoCodeError: '',
    validating: false,
    promoText: [
      {
        text: "We print immediately so you get your product on time. <div class='font-weight-bold'>No modifications can be made after you order.</div>",
        icon: 'mdi-check-circle'
      },
      {
        text: "If you don't love your prints, we'll work with you to make it right.",
        icon: 'mdi-check-circle'
      }
    ]
  }),

  computed: {
    canApplyPromoCode() {
      return !(this.appliedPromoCode && this.appliedPromoCode === this.cart.promoCode);
    },

    requireNonProfitInfo() {
      return (
        this.$store.getters['user/isMissionPrint'] && !this.$store.state.user.profile.companyInfo
      );
    },

    disablePlaceOrder() {
      if (!this.cardId || this.requireNonProfitInfo) return true;
      if (this.campaigns.length && !this.addressId) return true;
      return false;
      // return !this.cardId || this.requireNonProfitInfo || (this.campaigns.length && !this.addressId);
    },

    addressItems() {
      return this.addresses.map((x) => ({
        id: x.id,
        display: `${x.firstName} ${x.lastName} - ${x.line1} ${x.city}, ${this.state_abbr(
          x.stateProvinceId
        )} ${x.postalCode}`
      }));
    },

    campaigns() {
      return this.cart_lines.filter((x) => x.campaign);
    },

    ...mapGetters({
      cart: 'printcart/cart',
      cart_lines: 'printcart/cart_lines',
      project: 'projects/project',
      addresses: 'user/addresses',
      state_abbr: 'lookups/state_abbr',
      default_address: 'user/default_address',
      isMissionPrint: 'user/isMissionPrint',
      isTaxExempt: 'user/isTaxExempt'
    })
  },

  watch: {
    'cart.promoCode'(val, oldVal) {
      if (val !== oldVal) {
        this.couponApplied = false;
      }
    }
  },

  mounted() {
    this.addressId = this.campaigns.length ? this.campaigns[0].addressId : null;
    this.couponApplied = !!this.cart.promoCode;
    if (this.couponApplied) this.appliedPromoCode = this.cart.promoCode;
    if (this.$route.query.payment_intent) {
      this.placeOrder();
    }
  },

  methods: {
    changeAddress() {
      this.validating = true;
      this.$store
        .dispatch('printcart/getCart', {
          validate: false,
          calculateTax: true,
          addressId: this.addressId
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.validating = false;
        });
    },
    placeOrder() {
      this.$mixpanel.trackEvent(
        ANALYTICS_EVENTS.PRINT.NAME,
        ANALYTICS_EVENTS.PRINT.ACTIONS.ORDER_START
      );
      this.saving = true;
      var req = {
        cardId: this.cardId,
        paymentIntentId: this.$route.query.payment_intent
      };
      var supportMsg =
        '<br/><br/><strong>Need more help</strong>? Let us know at support@mycreativeshop.com';
      var orderAmount = this.cart.totalPrice;
      this.$store
        .dispatch('printcart/placeOrder', req)
        .then((result) => {
          if (result.success) {
            if (result.redirectToUrl) {
              window.location.href = result.redirectToUrl;
              return;
            }
            this.$emit('confirm', result);
            this.$mixpanel.trackEvent(
              ANALYTICS_EVENTS.PRINT.NAME,
              ANALYTICS_EVENTS.PRINT.ACTIONS.ORDER_SUCCESS
            );
            var gTagConversionData = {
              event_category: 'print',
              send_to: this.$store.getters['config/analytics'].conversionTrackingTag,
              value: orderAmount,
              currency: 'USD'
            };
            console.log('gTag conversion: ', gTagConversionData);
            this.$gtag.event('conversion', gTagConversionData);
          } else {
            this.$mixpanel.trackEvent(
              ANALYTICS_EVENTS.PRINT.NAME,
              ANALYTICS_EVENTS.PRINT.ACTIONS.ORDER_ERROR,
              {
                error: result.message
              }
            );
            this.$root.$alert('Error Placing Order', result.message + supportMsg, {
              closeOnly: true
            });
          }
        })
        .catch((error) => {
          var message = 'An error occurred while processing your order';
          if (error && error.response && error.response.status === 400 && error.response.data) {
            message = error.response.data;
          }
          this.$mixpanel.trackEvent(
            ANALYTICS_EVENTS.PRINT.NAME,
            ANALYTICS_EVENTS.PRINT.ACTIONS.ORDER_ERROR,
            {
              error: message
            }
          );
          this.$root.$alert('Error Placing Order', message + supportMsg, { closeOnly: true });
          this.$emit('fail', error);
        })
        .finally(() => {
          this.saving = false;
        });
    },

    refreshCart() {
      const previousPromoCode = this.cart.promoCode;
      this.refreshingCart = true;
      this.$store
        .dispatch('printcart/refreshCart', this.cart)
        .then(() => {
          this.$nextTick(function () {
            this.appliedPromoCode = this.cart.promoCode;
            const valid = this.cart.discount > 0;
            if (!valid) this.cart.promoCode = previousPromoCode;
            this.applyPromoCodeError = valid ? '' : 'This promo code is invalid.';
            this.couponApplied = this.cart.promoCode !== null;
          });
        })
        .finally(() => {
          setTimeout(() => {
            this.refreshingCart = false;
          }, 250);
        });
    },

    manageAddresses() {
      this.$store.commit('drawers/OPEN_DRAWER', DRAWERS.MANAGE_ADDRESSES);
    },

    taxExempt() {
      this.$store.commit('drawers/OPEN_DRAWER', DRAWERS.EXEMPTION_CERTIFICATES_DRAWER);
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .v-input__append-inner {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
</style>
