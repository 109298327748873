<template>
  <Drawer
    :id="drawerId"
    :title="title"
    padded
    @close="show = false"
  >
    <div v-show="!success">
      <div class="mb-2">We'd love to hear about your experience.</div>

      <v-form ref="form">
        <Rating
          v-model="review.rating"
          class="mb-5"
          size="32"
        />
        <v-text-field
          v-model="review.name"
          label="Name"
          placeholder="Name"
          persistent-placeholder
          outlined
          :rules="[$rules.required]"
        />
        <v-text-field
          v-model="review.company"
          label="Company"
          placeholder="Company"
          persistent-placeholder
          outlined
        />
        <v-textarea
          v-model="review.comment"
          label="Comment"
          outlined
          persistent-placeholder
          :rules="[$rules.required]"
        />
        <v-checkbox
          v-model="review.includeProjectImages"
          label="Include project images?"
          class="mt-0 pt-0"
        />
        <FileUpload
          v-model="files"
          multiple
          cta="Upload one or more images here"
          format-cta="Format (PNG, JPG)"
          :accept="`.png,.jpg`"
          class="mb-6"
        />

        <Portal :to="`drawerAppend-${drawerId}`">
          <div
            v-if="!success"
            class="pa-4"
          >
            <mcs-btn
              large
              block
              :loading="saving"
              @click="save"
            >
              Submit
            </mcs-btn>
          </div>
        </Portal>
      </v-form>
    </div>
    <v-slide-x-transition>
      <div v-show="success">
        <div class="text-center">
          <div>Your feedback is greatly appreciated!</div>
          <v-icon
            size="96"
            class="my-5"
            color="red"
          >
            mdi-heart
          </v-icon>
        </div>
        <mcs-btn
          large
          block
          @click="close"
        >
          Close
        </mcs-btn>
      </div>
    </v-slide-x-transition>
  </Drawer>
</template>

<script>
import Rating from '@/components/reviews/Rating';
import FileUpload from '@/components/utilities/FileUpload';
import { mapGetters } from 'vuex';
import Drawer from '../../components/layout/Drawer';
import { DRAWERS } from '../../utils/constants';

export default {
  name: 'ReviewDrawer',

  components: {
    Rating,
    Drawer,
    FileUpload
  },

  data() {
    return {
      drawerId: DRAWERS.REVIEW,
      review: {},
      files: [],
      saving: false,
      success: false
    };
  },

  computed: {
    title() {
      return this.success ? 'Thank you for your review!' : 'Submit your review';
    },

    ...mapGetters({
      initialData: 'drawers/data',
      drawerOpenedAt: 'drawers/lastOpenedAt',
      profile: 'user/profile'
    })
  },

  watch: {
    drawerOpenedAt() {
      if (this.drawerId === this.$store.getters['drawers/topDrawer']?.id) {
        this.success = false;
        this.comment = '';
        var data = this.initialData(this.drawerId);
        this.review = {
          rating: data.rating,
          orderId: data.orderId,
          name: this.profile.name,
          company: this.profile.company,
          includeProjectImages: true
        };
        this.files = [];
      }
    }
  },

  methods: {
    save() {
      var valid = this.$refs.form.validate();
      if (!valid) return;
      this.saving = true;
      this.$store
        .dispatch('orders/submitReview', { review: this.review, files: this.files })
        .finally(() => {
          setTimeout(() => {
            this.saving = false;
            this.success = true;
          }, 2000);
        });
    },

    close() {
      this.$store.commit('drawers/CLOSE_ALL');
    }
  }
};
</script>

<style></style>
