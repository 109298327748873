import enums from '@/utils/enums';
import { intersection } from 'lodash';
import VueRouter from 'vue-router';
import store from '../store/index';
import { ROUTES } from '../utils/constants';

// this will suppress the navigation to same path error
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return new Promise((resolve, reject) => {
    originalPush.call(
      this,
      location,
      () => {
        resolve(this.currentRoute);
      },
      (error) => {
        // on abort
        // only ignore NavigationDuplicated error
        if (error.name === 'NavigationDuplicated') {
          resolve(this.currentRoute);
        } else {
          reject(error);
        }
      }
    );
  });
};

const routes = [
  {
    name: ROUTES.DASHBOARD.NAME,
    path: ROUTES.DASHBOARD.PATH,
    meta: { name: ROUTES.DASHBOARD.DISPLAY },
    component: () => import(/* webpackChunkName: "dashboard", */ '../views/Dashboard')
  },
  {
    name: ROUTES.PROJECTS.NAME,
    path: ROUTES.PROJECTS.PATH,
    meta: { name: ROUTES.PROJECTS.DISPLAY },
    component: () => import(/* webpackChunkName: "projects" */ '../views/projects/AllProjects')
  },
  {
    name: ROUTES.PROJECT_DETAIL.NAME,
    path: ROUTES.PROJECT_DETAIL.PATH + '/:id',
    meta: {
      name: ROUTES.PROJECT_DETAIL.DISPLAY,
      breadcrumbs: [ROUTES.PROJECTS]
    },
    component: () =>
      import(/* webpackChunkName: "project-details" */ '../views/projects/ProjectDetail')
  },
  {
    name: ROUTES.TEAM_PROJECTS.NAME,
    path: ROUTES.TEAM_PROJECTS.PATH,
    meta: { name: ROUTES.TEAM_PROJECTS.DISPLAY },
    component: () => import(/* webpackChunkName: "shared-projects" */ '../views/projects/shared/List')
  },
  {
    name: ROUTES.TEAM_PROJECTS_CHILD.NAME,
    path: ROUTES.TEAM_PROJECTS_CHILD.PATH,
    meta: { name: ROUTES.TEAM_PROJECTS_CHILD.DISPLAY },
    component: () => import(/* webpackChunkName: "shared-projects" */ '../views/projects/shared/List')
  },
  {
    name: ROUTES.TEAM_PROJECT_DETAIL.NAME,
    path: ROUTES.TEAM_PROJECT_DETAIL.PATH,
    meta: {
      name: ROUTES.TEAM_PROJECT_DETAIL.DISPLAY,
      breadcrumbs: [ROUTES.TEAM_PROJECTS]
    },
    component: () => import(/* webpackChunkName: "shared-projects" */ '../views/projects/shared/Detail')
  },
  {
    name: ROUTES.PRINT.NAME,
    path: ROUTES.PRINT.PATH,
    meta: { name: ROUTES.PRINT.DISPLAY },
    component: () => import(/* webpackChunkName: "print" */ '../views/Print')
  },
  {
    name: ROUTES.SUBSCRIPTION.NAME,
    path: ROUTES.SUBSCRIPTION.PATH,
    meta: { name: ROUTES.SUBSCRIPTION.DISPLAY },
    component: () => import(/* webpackChunkName: "subscription" */ '../views/Subscription')
  },
  {
    name: ROUTES.ORDER_HISTORY.NAME,
    path: ROUTES.ORDER_HISTORY.PATH,
    meta: { name: ROUTES.ORDER_HISTORY.DISPLAY },
    component: () => import(/* webpackChunkName: "order-history" */ '../views/OrderHistory')
  },
  {
    name: ROUTES.ORDER_DETAIL.NAME,
    path: ROUTES.ORDER_DETAIL.PATH + '/:id',
    meta: { name: ROUTES.ORDER_DETAIL.DISPLAY, title: 'Order Detail - ' + ':id' },
    component: () => import(/* webpackChunkName: "order-detail" */ '../views/OrderDetail')
  },
  {
    name: ROUTES.CARDS.NAME,
    path: ROUTES.CARDS.PATH,
    meta: { name: ROUTES.CARDS.DISPLAY },
    component: () => import(/* webpackChunkName: "cards" */ '../views/Cards')
  },
  {
    name: ROUTES.PROFILE.NAME,
    path: ROUTES.PROFILE.PATH,
    meta: { name: ROUTES.PROFILE.DISPLAY },
    component: () => import(/* webpackChunkName: "profile" */ '../views/Profile')
  },
  {
    name: ROUTES.CONTRIBUTOR_DESIGN_PROJECTS.NAME,
    path: ROUTES.CONTRIBUTOR_DESIGN_PROJECTS.PATH,
    meta: { name: ROUTES.CONTRIBUTOR_DESIGN_PROJECTS.DISPLAY, title: 'Designer Projects' },
    component: () =>
      import(
        /* webpackChunkName: "contributor-projects" */ '../views/contributor/ContributorDesignProjects'
      )
  },
  {
    name: ROUTES.CONTRIBUTOR_ASSET_PROJECTS.NAME,
    path: ROUTES.CONTRIBUTOR_ASSET_PROJECTS.PATH,
    meta: { name: ROUTES.CONTRIBUTOR_ASSET_PROJECTS.DISPLAY, title: 'Asset Projects' },
    component: () =>
      import(
        /* webpackChunkName: "asset-projects" */ '../views/contributor/ContributorAssetProjects'
      )
  },
  {
    name: ROUTES.CONTRIBUTOR_IMAGE_PROJECTS.NAME,
    path: ROUTES.CONTRIBUTOR_IMAGE_PROJECTS.PATH,
    meta: { name: ROUTES.CONTRIBUTOR_IMAGE_PROJECTS.DISPLAY, title: 'Image Projects' },
    component: () =>
      import(
        /* webpackChunkName: "image-projects" */ '../views/contributor/ContributorImageProjects'
      )
  },
  {
    name: ROUTES.CONTRIBUTOR_CUSTOMER_PROJECTS.NAME,
    path: ROUTES.CONTRIBUTOR_CUSTOMER_PROJECTS.PATH,
    meta: { name: ROUTES.CONTRIBUTOR_CUSTOMER_PROJECTS.DISPLAY, title: 'Custmer Projects' },
    component: () =>
      import(/* webpackChunkName: "contributor-projects" */ '../views/contributor/CustomerProjects')
  },
  {
    name: ROUTES.CONTRIBUTOR_PAYOUTS.NAME,
    path: ROUTES.CONTRIBUTOR_PAYOUTS.PATH,
    meta: { name: ROUTES.CONTRIBUTOR_PAYOUTS.DISPLAY, title: 'Designer Payouts' },
    component: () =>
      import(
        /* webpackChunkName: "contributor-payouts" */ '../views/contributor/ContributorPayouts'
      )
  },
  {
    name: ROUTES.CAMPAIGN_IDEAS.NAME,
    path: ROUTES.CAMPAIGN_IDEAS.PATH,
    meta: { name: ROUTES.CAMPAIGN_IDEAS.DISPLAY, title: 'Campaign Ideas' },
    component: () => import(/* webpackChunkName: "campaigns" */ '../views/CampaignIdeas')
  },
  {
    name: ROUTES.ADMIN_APP_LINKS.NAME,
    path: ROUTES.ADMIN_APP_LINKS.PATH,
    meta: { name: ROUTES.ADMIN_APP_LINKS.DISPLAY },
    component: () => import(/* webpackChunkName: "admin-app-links" */ '../views/admin/AppLinks')
  },
  {
    name: ROUTES.ADMIN_BANKING.NAME,
    path: ROUTES.ADMIN_BANKING.PATH,
    meta: { name: ROUTES.ADMIN_BANKING.DISPLAY },
    component: () => import(/* webpackChunkName: "admin-banking" */ '../views/admin/Banking')
  },
  {
    name: ROUTES.ADMIN_SETTINGS.NAME,
    path: ROUTES.ADMIN_SETTINGS.PATH,
    meta: { name: ROUTES.ADMIN_SETTINGS.DISPLAY },
    component: () => import(/* webpackChunkName: "admin-settings" */ '../views/admin/Settings')
  },
  {
    name: ROUTES.ADMIN_THEME.NAME,
    path: ROUTES.ADMIN_THEME.PATH,
    meta: { name: ROUTES.ADMIN_THEME.DISPLAY, breadcrumbs: [ROUTES.ADMIN_SETTINGS] },
    component: () => import(/* webpackChunkName: "admin-settings" */ '../views/admin/Theme')
  },
  {
    name: ROUTES.ADMIN_COMPANIES.NAME,
    path: ROUTES.ADMIN_COMPANIES.PATH,
    meta: { name: ROUTES.ADMIN_COMPANIES.DISPLAY, breadcrumbs: [] },
    component: () => import(/* webpackChunkName: "admin-settings" */ '../views/admin/Companies')
  },
  {
    name: ROUTES.ADMIN_SUBSCRIPTION.NAME,
    path: ROUTES.ADMIN_SUBSCRIPTION.PATH,
    meta: { name: ROUTES.ADMIN_SUBSCRIPTION.DISPLAY },
    component: () => import(/* webpackChunkName: "admin-subscription" */ '../views/admin/Subscription')
  },
  {
    name: ROUTES.ADMIN_MASTER_ACCOUNTS.NAME,
    path: ROUTES.ADMIN_MASTER_ACCOUNTS.PATH,
    meta: { name: ROUTES.ADMIN_MASTER_ACCOUNTS.DISPLAY },
    component: () =>
      import(/* webpackChunkName: "admin-master-accounts" */ '../views/admin/MasterAccounts')
  },
  {
    name: ROUTES.ADMIN_ORDERS_PAYOUTS.NAME,
    path: ROUTES.ADMIN_ORDERS_PAYOUTS.PATH,
    meta: {
      name: ROUTES.ADMIN_ORDERS_PAYOUTS.DISPLAY,
      breadcrumbs: [ROUTES.ADMIN_ORDERS]
    },
    component: () =>
      import(/* webpackChunkName: "admin-reports-payouts" */ '../views/admin/orders/Payouts')
  },
  {
    name: ROUTES.ADMIN_ORDERS_PAYMENTS.NAME,
    path: ROUTES.ADMIN_ORDERS_PAYMENTS.PATH,
    meta: {
      name: ROUTES.ADMIN_ORDERS_PAYMENTS.DISPLAY,
      breadcrumbs: [ROUTES.ADMIN_ORDERS]
    },
    component: () =>
      import(/* webpackChunkName: "admin-reports-payments" */ '../views/admin/orders/Payments')
  },
  {
    name: ROUTES.ADMIN_DESIGN_FEED.NAME,
    path: ROUTES.ADMIN_DESIGN_FEED.PATH,
    meta: {
      name: ROUTES.ADMIN_DESIGN_FEED.DISPLAY
    },
    component: () => import(/* webpackChunkName: "admin-design-feed" */ '../views/admin/DesignFeed')
  },
  {
    name: ROUTES.ADMIN_EXEMPTION_CERTS.NAME,
    path: ROUTES.ADMIN_EXEMPTION_CERTS.PATH,
    meta: { name: ROUTES.ADMIN_EXEMPTION_CERTS.DISPLAY },
    component: () =>
      import(
        /* webpackChunkName: "admin-exemption-certificates" */ '../views/admin/exemptionCertificates/ManageCertificates'
      )
  },
  {
    name: ROUTES.ADMIN_PRODUCT_SETUP.NAME,
    path: ROUTES.ADMIN_PRODUCT_SETUP.PATH,
    meta: {
      name: ROUTES.ADMIN_PRODUCT_SETUP.DISPLAY,
      breadcrumbs: [ROUTES.ADMIN_SETTINGS]
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-product-setup-entry" */ '../views/admin/productSetup/Entry'
      )
  },
  {
    name: ROUTES.ADMIN_SETUP_INDUSTRIES.NAME,
    path: ROUTES.ADMIN_SETUP_INDUSTRIES.PATH,
    meta: {
      name: ROUTES.ADMIN_SETUP_INDUSTRIES.DISPLAY,
      breadcrumbs: [ROUTES.ADMIN_SETTINGS, ROUTES.ADMIN_PRODUCT_SETUP]
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-product-setup-industries" */ '../views/admin/productSetup/Industries'
      )
  },
  {
    name: ROUTES.ADMIN_SETUP_CATEGORY.NAME,
    path: ROUTES.ADMIN_SETUP_CATEGORY.PATH,
    meta: {
      name: ROUTES.ADMIN_SETUP_CATEGORY.DISPLAY,
      breadcrumbs: [ROUTES.ADMIN_SETTINGS, ROUTES.ADMIN_PRODUCT_SETUP]
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-product-setup-category" */ '../views/admin/productSetup/Category'
      )
  },
  {
    name: ROUTES.ADMIN_SETUP_COREPRODUCT.NAME,
    path: ROUTES.ADMIN_SETUP_COREPRODUCT.PATH,
    meta: {
      name: ROUTES.ADMIN_SETUP_COREPRODUCT.DISPLAY,
      breadcrumbs: [ROUTES.ADMIN_SETTINGS, ROUTES.ADMIN_PRODUCT_SETUP, ROUTES.ADMIN_SETUP_CATEGORY]
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-setup-coreproduct" */ '../views/admin/productSetup/Product'
      )
  },
  {
    name: ROUTES.ADMIN_SETUP_MATERIAL.NAME,
    path: ROUTES.ADMIN_SETUP_MATERIAL.PATH,
    meta: {
      name: ROUTES.ADMIN_SETUP_MATERIAL.DISPLAY,
      breadcrumbs: [
        ROUTES.ADMIN_SETTINGS,
        ROUTES.ADMIN_PRODUCT_SETUP,
        ROUTES.ADMIN_SETUP_CATEGORY,
        ROUTES.ADMIN_SETUP_COREPRODUCT
      ]
    },
    component: () =>
      import(/* webpackChunkName: "admin-setup-material" */ '../views/admin/productSetup/Material')
  },
  {
    name: ROUTES.ADMIN_PEOPLE.NAME,
    path: ROUTES.ADMIN_PEOPLE.PATH,
    meta: { name: ROUTES.ADMIN_PEOPLE.DISPLAY },
    component: () => import(/* webpackChunkName: "admin-users" */ '../views/admin/people/List')
  },
  {
    name: ROUTES.ADMIN_ATTRIBUTES.NAME,
    path: ROUTES.ADMIN_ATTRIBUTES.PATH,
    meta: { name: ROUTES.ADMIN_ATTRIBUTES.DISPLAY, breadcrumbs: [ROUTES.ADMIN_PEOPLE] },
    component: () => import(/* webpackChunkName: "admin-custom-attributes" */ '../views/admin/Attributes')
  },
  {
    name: ROUTES.ADMIN_TEAMS.NAME,
    path: ROUTES.ADMIN_TEAMS.PATH,
    meta: {
      name: ROUTES.ADMIN_TEAMS.DISPLAY,
      breadcrumbs: []
    },
    component: () =>
      import(/* webpackChunkName: "admin-collections" */ '../views/admin/collections/List')
  },
  {
    name: ROUTES.ADMIN_TEAM_DETAIL.NAME,
    path: ROUTES.ADMIN_TEAM_DETAIL.PATH,
    meta: {
      name: ROUTES.ADMIN_TEAM_DETAIL.DISPLAY,
      breadcrumbs: [ROUTES.ADMIN_TEAMS]
    },
    component: () =>
      import(/* webpackChunkName: "admin-collections" */ '../views/admin/collections/Detail')
  },
  {
    name: ROUTES.ADMIN_TEAM_DETAIL_TYPE.NAME,
    path: ROUTES.ADMIN_TEAM_DETAIL_TYPE.PATH,
    meta: {
      name: ROUTES.ADMIN_TEAM_DETAIL_TYPE.DISPLAY,
      breadcrumbs: [ROUTES.ADMIN_TEAMS, ROUTES.ADMIN_TEAM_DETAIL]
    },
    component: () =>
      import(/* webpackChunkName: "admin-collections" */ '../views/admin/collections/Type')
  },
  {
    name: ROUTES.ADMIN_TEAM_DETAIL_TYPE_CHILD.NAME,
    path: ROUTES.ADMIN_TEAM_DETAIL_TYPE_CHILD.PATH,
    meta: {
      name: ROUTES.ADMIN_TEAM_DETAIL_TYPE_CHILD.DISPLAY,
      breadcrumbs: [ROUTES.ADMIN_TEAMS, ROUTES.ADMIN_TEAM_DETAIL]
    },
    component: () =>
      import(/* webpackChunkName: "admin-collections" */ '../views/admin/collections/Type')
  },
  {
    name: ROUTES.ADMIN_API_DOCS.NAME,
    path: ROUTES.ADMIN_API_DOCS.PATH,
    meta: { name: ROUTES.ADMIN_API_DOCS.DISPLAY },
    component: () => import(/* webpackChunkName: "admin-api-docs" */ '../views/admin/api/Index')
  },
  {
    name: ROUTES.PRINT_CHECKOUT_IFRAME.NAME,
    path: ROUTES.PRINT_CHECKOUT_IFRAME.PATH,
    meta: { name: 'Print Checkout', iframe: true },
    component: () =>
      import(/* webpackChunkName: "print-checkout-iframe" */ '../views/PrintCheckoutIframe')
  },
  {
    name: ROUTES.SUBSCRIPTION_CHECKOUT_IFRAME.NAME,
    path: ROUTES.SUBSCRIPTION_CHECKOUT_IFRAME.PATH,
    meta: { name: 'Subscription Checkout', iframe: true },
    component: () =>
      import(
        /* webpackChunkName: "subscription-checkout-iframe" */ '../views/SubscriptionCheckoutIframe'
      )
  },
  {
    name: ROUTES.ADMIN_ORDERS.NAME,
    path: ROUTES.ADMIN_ORDERS.PATH,
    meta: { name: ROUTES.ADMIN_ORDERS.DISPLAY },
    component: () => import(/* webpackChunkName: "admin-orders" */ '../views/admin/orders/List')
  },
  {
    name: ROUTES.ADMIN_STYLES.NAME,
    path: ROUTES.ADMIN_STYLES.PATH,
    meta: { name: ROUTES.ADMIN_STYLES.DISPLAY },
    component: () => import(/* webpackChunkName: "admin-orders" */ '../views/admin/Styles')
  },
  {
    name: ROUTES.TOOLS.NAME,
    path: ROUTES.TOOLS.PATH,
    meta: { name: ROUTES.TOOLS.DISPLAY },
    component: () => import(/* webpackChunkName: "tools" */ '../views/tools/List')
  },
  {
    name: ROUTES.TOOL_DETAIL.NAME,
    path: ROUTES.TOOL_DETAIL.PATH,
    meta: {
      name: ROUTES.TOOL_DETAIL.DISPLAY,
      breadcrumbs: [ROUTES.TOOLS]
    },
    component: () => import(/* webpackChunkName: "tools" */ '../views/tools/Detail')
  },
  {
    name: ROUTES.TOOL_DETAIL_TAB.NAME,
    path: ROUTES.TOOL_DETAIL_TAB.PATH,
    meta: {
      name: ROUTES.TOOL_DETAIL_TAB.DISPLAY,
      breadcrumbs: [ROUTES.TOOLS]
    },
    component: () => import(/* webpackChunkName: "tools" */ '../views/tools/Detail')
  },
  {
    name: ROUTES.NOTFOUND.NAME,
    path: ROUTES.NOTFOUND.PATH,
    meta: { title: 'Page Not Found' },
    component: () => import(/* webpackChunkName: "notfound" */ '../views/NotFound')
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    meta: { title: 'Page Not Found' },
    component: () => import(/* webpackChunkName: "notfound" */ '../views/NotFound')
  }
];

const router = new VueRouter({
  routes,
  mode: 'history',
  base: '/',
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});

router.beforeEach((to, from, next) => {
  if (!canAccess(to)) {
    next(ROUTES.DASHBOARD);
  } else {
    next();
  }
});

router.afterEach((to, from, failure) => {
  document.title = to.meta?.name || to.meta?.title || 'unset';
  store.commit('config/SET_ROUTE', to);
  setTimeout(function () {
    if (!canAccess(to)) {
      router.push({ name: ROUTES.DASHBOARD.NAME });
    }
  }, 500);
});

function canAccess(to) {
  const route = Object.keys(ROUTES).map((key) => ROUTES[key]).find(x => x.NAME === to.name);
  const collections = store.state.user.profile.collections;
  if (route) {
    if (route.AUTHORIZE && route.AUTHORIZE.length && store.state.user.roles.length) {
      const intersect = intersection(store.state.user.roles, route.AUTHORIZE);
      return !!intersect.length;
    } else if (route.AUTHORIZE_TEAM && route.AUTHORIZE_TEAM.length && collections && !store.getters['user/isAdmin']) {
      if (to.params.collectionId) {
        return !!collections.filter(x => route.AUTHORIZE_TEAM.includes(x.roleId) && x.collectionId === parseInt(to.params.collectionId)).length;
      } else {
        if (!store.getters['user/isMasterAccountOwner']) {
          // allow through if this route allows no collections, they are in MCS, and they have no collections
          if (store.getters['user/isMCS'] && route.ALLOW_NO_COLLECTIONS && !collections.length) {
            return true;
          }
          return !!collections.filter(x => x.roleId !== enums.COLLECTION_ROLES.MEMBER).length;
        }
      }
    }
  }
  return true;
}

export default router;
