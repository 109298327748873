<template>
  <mcs-sheet
    no-padding
    class="border-grey border-top bg-non-profit d-flex relative overflow-hidden align-center"
  >
    <img
      src="https://res.cloudinary.com/mycreativeshop/image/upload/f_auto/v1712678297/public/nonprofit-saver"
      style="width: 108px"
      class="ml-n1 d-none d-sm-block align-self-end absolute"
    />
    <div
      class="my-3"
      style="margin-left: 120px"
    >
      <div class="title">Add your nonprofit info</div>
      <mcs-btn
        outlined
        class="mt-3 px-8"
        @click="open"
      >
        Add details
      </mcs-btn>
    </div>
  </mcs-sheet>
</template>

<script>
import { DRAWERS } from '../../../utils/constants';

export default {
  name: 'NonProfitRequired',

  components: {},

  data() {
    return {};
  },

  computed: {},

  methods: {
    open() {
      this.$store.commit('drawers/OPEN_DRAWER', DRAWERS.NON_PROFIT_DRAWER);
    }
  }
};
</script>

<style scoped></style>
