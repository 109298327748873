import enums from '@/utils/enums';
import { orderBy } from 'lodash';
import { COLORS, EXEMPTION_CERTIFICATE_STATUS } from '../../utils/constants';

const state = {
  username: '',
  userId: '',
  userInfoId: '',
  roles: [],
  profile: {
    companyInfo: {},
    taxExemptCertificates: []
  },
  masterAccount: {},
  plan: {},
  hasOrdered: false,
  hasPrinted: false,
  hasSubscribed: false,
  hasProjects: false,
  reviewSubscriptionOrderId: 0,
  addresses: [],
  candidates: []
};

const getters = {
  authorized(state) {
    return state.username !== '';
  },

  avatarUploaded(state) {
    return state.profile.avatar?.indexOf('profile-blank') === -1;
  },

  isMCS(state) {
    return state.masterAccount.accountId === 'mcs';
  },

  isMissionPrint(state) {
    return state.masterAccount.accountId === 'missionprint';
  },

  isSprayNet(state) {
    return state.masterAccount.accountId === 'spraynet';
  },

  enableMcsTemplates(state) {
    return state.masterAccount.enableMcsTemplates;
  },

  enableApi(state, getters) {
    return state.masterAccount.enableApi || (getters.isMCS && getters.isAdmin);
  },

  enablePrint(state, getters) {
    return state.masterAccount.enablePrint || getters.isMCS;
  },

  enablePrintReselling(state, getters) {
    return state.masterAccount.enablePrintReselling || getters.isMCS;
  },

  enableCampaignIdeas(state, getters) {
    return state.masterAccount.enableCampaignIdeas;
  },

  enablePortal(state) {
    return state.masterAccount.enablePortal;
  },

  enableCompanies(state) {
    return state.masterAccount.enableCompanies;
  },

  isChildMasterAccount(state) {
    return !!state.masterAccount.parentId;
  },

  isPortalPublic(state) {
    return state.masterAccount.isPortalPublic;
  },

  prefersMetric(state) {
    return state.masterAccount.prefersMetric;
  },

  applicationFee(state) {
    return state.masterAccount.applicationFee;
  },

  enableShare(state) {
    return state.masterAccount.enableShare;
  },

  shareWithTeam(state, getters, rootState, rootGetters) {
    return !!getters.collections_to_manage.length;
  },

  isAdmin(state) {
    return state.roles.includes(enums.ROLES.ADMIN);
  },

  isMasterAccountOwner(state) {
    return state.roles.includes(enums.ROLES.MASTER_ACCOUNT_OWNER);
  },

  isDesigner(state) {
    return state.roles.includes(enums.ROLES.DESIGNER);
  },

  isAssetContributor(state) {
    return state.roles.includes(enums.ROLES.ASSET_CONTRIBUTOR);
  },

  isImageContributor(state) {
    return state.roles.includes(enums.ROLES.IMAGE_CONTRIBUTOR);
  },

  hasOrdered() {
    return state.hasOrdered;
  },

  profile(state) {
    return state.profile || {};
  },

  collections(state, getters) {
    return getters.profile.collections || [];
  },

  collections_to_manage(state, getters) {
    return getters.collections.filter(x => getters.teamDesignerAndUp(x.collectionId));
  },

  master_account(state) {
    return state.masterAccount || {};
  },

  plan(state) {
    return state.plan || {};
  },

  isSubscriber(state, getters) {
    return getters.plan.isSubscriber;
  },

  isPaidPlan(state, getters) {
    if (!getters.authorized) {
      return false;
    }
    if (!getters.isMCS || getters.isMasterAccountOwner) {
      return true;
    }
    return getters.plan.isSubscriber && !getters.plan.subscriptionIsCanceled;
  },

  attributes(state, getters) {
    return getters.master_account.attributes || [];
  },

  isUSA(state) {
    return state.profile.country?.iso === 'US';
  },

  addresses(state) {
    return state.addresses || [];
  },

  default_address(state, getters) {
    const address = orderBy(getters.addresses, ['isDefault', 'validated'], ['desc', 'desc'])[0];
    return address || {};
  },

  company_info(state, getters) {
    return getters.profile.companyInfo || { address: {} };
  },

  isTaxExempt(state, getters) {
    return getters.company_info.taxExempt;
  },

  candidates(state) {
    return state.candidates || [];
  },

  taxExemptCertificates(state) {
    var certs = [];
    state.profile.taxExemptCertificates.forEach((cert) => {
      if (cert.statusId === EXEMPTION_CERTIFICATE_STATUS.APPROVED) {
        cert.color = COLORS.SUCCESS.HEX;
        cert.status = 'Approved';
      } else if (cert.statusId === EXEMPTION_CERTIFICATE_STATUS.UNDER_REVIEW) {
        cert.color = COLORS.ACCENT.HEX;
        cert.status = 'Under Review';
      } else {
        cert.color = COLORS.ERROR.HEX;
        cert.status = 'Rejected';
      }
      certs.push(cert);
    });
    return certs;
  },

  hasCollectionTemplates(state, getters) {
    return getters.profile.templateCount > 0 && !getters.isSprayNet;
  },

  hasProjects(state, getters, rootState, rootGetters) {
    return state.hasProjects || rootGetters['projects/all'].length > 0;
  },

  isCollectionOwner(state) {
    return state.plan.isTeamsAdmin;
  },

  isTeamsUser(state) {
    return state.plan.isTeamsUser;
  },

  teamAdminAndUp(state, getters) {
    return (collectionId) => {
      const roleIds = [enums.COLLECTION_ROLES.OWNER, enums.COLLECTION_ROLES.ADMIN];
      return getters.isMasterAccountOwner || !!getters.collections.filter(x => x.collectionId === collectionId && roleIds.includes(x.roleId)).length;
    };
  },

  teamDesignerAndUp(state, getters) {
    return (collectionId) => {
      const roleIds = [enums.COLLECTION_ROLES.OWNER, enums.COLLECTION_ROLES.ADMIN, enums.COLLECTION_ROLES.DESIGNER];
      return getters.isMasterAccountOwner || !!getters.collections.filter(x => x.collectionId === collectionId && roleIds.includes(x.roleId)).length;
    };
  }
};

const actions = {
  authorize({ commit, rootState }) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .get(`auth/authorize?url=${window.location.href}`)
        .then((response) => {
          commit('SET_IDENTITY', response.data);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  runas({ commit }, email) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .post(`auth/runas?user=${email}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getData({ commit }) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .get('user/getData')
        .then((data) => {
          commit('SET_USER_DATA', data);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  refreshProfile({ commit }) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .get('user/refreshProfile')
        .then((response) => {
          commit('UPDATE_PROFILE', response.data);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getAddresses({ commit }) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .get('user/getAddresses')
        .then((response) => {
          commit('SET_ADDRESSES', response.data);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  saveOccupation({ commit }, selectItem) {
    return new Promise((resolve, reject) => {
      var payload = {
        userId: state.userId,
        occupationId: selectItem.key
      };
      this._vm.$api
        .post('user/setOccupation', payload)
        .then(() => {
          commit('SET_OCCUPATION', {
            occupation: selectItem.value,
            occupationId: selectItem.key
          });
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  saveWorkArea({ commit }, selectItem) {
    return new Promise((resolve, reject) => {
      var payload = {
        userId: state.userId,
        workAreaId: selectItem.key
      };
      this._vm.$api
        .post('user/setWorkArea', payload)
        .then(() => {
          commit('SET_WORK_AREA', {
            workArea: selectItem.value,
            workAreaId: selectItem.key
          });
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  uploadProfileImage({ commit }, file) {
    return new Promise((resolve, reject) => {
      var formData = new FormData();
      formData.append('file', file);
      this._vm.$api
        .post('user/uploadProfileImage', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((response) => {
          commit('SET_AVATAR', response.data);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  updateAccountDetails({ commit }, payload) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .post('user/updateAccountDetails', payload)
        .then((response) => {
          commit('UPDATE_PROFILE', response.data);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  updatePassword({ commit }, payload) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .post('user/updatePassword', payload)
        .then((response) => {
          commit('UPDATE_PROFILE', response.data);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  updatePreferences({ commit }, payload) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .post('user/updatePreferences', payload)
        .then((response) => {
          commit('UPDATE_PROFILE', response.data);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  updateCompanyInfo({ commit }, payload) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .post('user/updateCompanyInfo', payload)
        .then((response) => {
          commit('UPDATE_PROFILE', response.data);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  addExemptionCertificateFile({ commit }, payload) {
    return new Promise((resolve, reject) => {
      var formData = new FormData();
      formData.append('file', payload.file);
      this._vm.$api
        .post(`user/addExemptionCertificateFile?certificateId=${payload.certificateId}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((response) => {
          commit('UPDATE_PROFILE', response.data);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  updateExemptionCertificate({ commit }, payload) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .post('user/updateExemptionCertificate', payload)
        .then((response) => {
          commit('UPDATE_PROFILE', response.data);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  deleteExemptionCertificate({ commit }, id) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .post(`user/deleteExemptionCertificate?id=${id}`)
        .then(() => {
          commit('REMOVE_CERTIFICATE', id);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  deleteAccount({ commit }) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .post('user/deleteAccount')
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  isUsernameAvailable({ commit }, username) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .post(`user/isUsernameAvailable?username=${username}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  validateAndSaveAddress({ commit }, address) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .post('user/validateAndSaveAddress', address)
        .then((response) => {
          var data = response.data;
          if (data.address && data.address.id) {
            commit('SAVE_ADDRESS', data.address);
          }
          commit('SET_CANDIDATES', data.candidates || []);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  saveAddress({ commit }, address) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .post('user/saveAddress', address)
        .then((response) => {
          commit('SAVE_ADDRESS', response.data);
          commit('SET_CANDIDATES', []);
          resolve(response.data.id);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  deleteAddress({ commit }, id) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .delete(`user/deleteAddress?id=${id}`)
        .then(() => {
          commit('DELETE_ADDRESS', id);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  clearCandidates({ commit }) {
    return new Promise((resolve, reject) => {
      commit('SET_CANDIDATES', []);
      resolve();
    });
  },

  createFromGuest({ commit }, payload) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .post('user/createFromGuest', payload)
        .then((response) => {
          if (response.data.result.success) {
            commit('MIGRATED_TO_ACCOUNT', {
              username: payload.username,
              plan: response.data.plan
            });
            resolve();
          } else {
            reject(new Error(response.data.result.message));
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  toggleMailboxDatesOptin({ commit }) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .post(`user/setMailboxDatesOptIn?optIn=${!state.profile.mailboxDatesOptIn}`)
        .then(() => {
          commit('UPDATE_MAILBOX_OPTIN', !state.profile.mailboxDatesOptIn);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async bulkAddUsers({ commit }, req) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .post(
          'user/bulkAddUsers',
          req
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
};

const mutations = {
  SET_IDENTITY(state, payload) {
    state.username = payload.username;
    state.userId = payload.userId;
    state.userInfoId = payload.userInfoId;
    state.roles = payload.roles;
  },

  INIT_APP_DATA(state, data) {
    state.masterAccount = data.masterAccount;
    state.profile = data.profile;
    state.plan = data.plan;
  },

  INIT_LAZY_APP_DATA(state, data) {
    state.hasOrdered = data.userHasOrdered;
    state.hasPrinted = data.userHasPrinted;
    state.hasSubscribed = data.userHasSubscribed;
    state.reviewSubscriptionOrderId = data.reviewSubscriptionOrderId;
    state.addresses = data.userAddresses;
  },

  SET_USER_DATA(state, payload) {
    state.profile = payload.profile;
    state.plan = payload.plan;
  },

  MIGRATED_TO_ACCOUNT(state, payload) {
    state.username = payload.username;
    state.plan = payload.plan;
  },

  UPDATE_PROFILE(state, payload) {
    state.profile = payload;
  },

  SET_AVATAR(state, url) {
    state.profile.avatar = url;
  },

  UPDATE_HAS_ORDERED(state, isPrintOrder) {
    state.hasOrdered = true;
    state.hasPrinted = isPrintOrder;
  },

  UPDATE_PLAN(state, payload) {
    state.plan = payload;
  },

  UPDATE_MAILBOX_OPTIN(state, payload) {
    state.profile.mailboxDatesOptIn = payload;
  },

  SET_OCCUPATION(state, payload) {
    state.profile.profession.occupationId = payload.occupationId;
    state.profile.profession.occupation = payload.occupation;
  },

  SET_WORK_AREA(state, payload) {
    state.profile.profession.workAreaId = payload.workAreaId;
    state.profile.profession.workArea = payload.workArea;
  },

  SET_HAS_ORDERED(state, hasOrdered) {
    state.hasOrdered = hasOrdered;
  },

  SET_ADDRESSES(state, list) {
    state.addresses = list;
  },

  SAVE_ADDRESS(state, item) {
    const index = state.addresses.findIndex((x) => x.id === item.id);
    if (index >= 0) {
      this._vm.$set(state.addresses, index, item);
    } else {
      state.addresses.push(item);
    }
    if (item.isDefault) {
      for (var address of state.addresses) {
        address.isDefault = address.id === item.id;
      }
    }
  },

  DELETE_ADDRESS(state, id) {
    const index = state.addresses.findIndex((x) => x.id === id);
    if (index >= 0) {
      state.addresses.splice(index, 1);
    }
  },

  SET_CANDIDATES(state, list) {
    state.candidates = list;
  },

  CLEAR_SUBSCRIPTION_REVIEW(state) {
    state.reviewSubscriptionOrderId = 0;
  },

  SET_MASTER_ACCOUNT_COLOR(state, payload) {
    state.masterAccount.primaryColor = payload;
  },

  REMOVE_CERTIFICATE(state, id) {
    state.profile.taxExemptCertificates = state.profile.taxExemptCertificates.filter(
      (x) => x.id !== id
    );
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
