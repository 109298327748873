const state = {
  orders: [],
  order: {},
  invoices: [],
  invoice: {}
};

const getters = {
  orders(state) {
    return state.orders || [];
  }
};

const actions = {
  getAll({ commit, rootState }) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .get('order/getAll')
        .then((response) => {
          commit('SET_ORDERS', response.data.orders);
          commit('SET_INVOICES', response.data.invoices);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  get({ commit, rootState }, id) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .get(`order/get?id=${id}`)
        .then((response) => {
          commit('SET_ORDER', response.data);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getCustomInvoice({ commit }, id) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .get(`invoice/get?id=${id}`)
        .then((response) => {
          commit('SET_INVOICE', response.data);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  submitReview({ commit, rootState }, req) {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      for (var file of req.files) {
        formData.append('file', file);
      }
      formData.append('payload', JSON.stringify(req.review));
      this._vm.$api
        .post('order/submitReview', formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        })
        .then(() => {
          if (req.review.orderId === rootState.user.reviewSubscriptionOrderId) {
            commit('user/CLEAR_SUBSCRIPTION_REVIEW', null, { root: true });
          } else {
            commit('SET_ORDER_REVIEWED', req.review.orderId);
          }
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
};

const mutations = {
  SET_ORDERS(state, orders) {
    state.orders = orders;
  },

  SET_ORDER(state, order) {
    state.order = order;
  },

  SET_INVOICES(state, invoices) {
    state.invoices = invoices;
  },

  SET_INVOICE(state, invoice) {
    state.invoice = invoice;
  },

  SET_ORDER_REVIEWED(state, orderId) {
    var index = state.orders.findIndex((x) => x.id === orderId);
    if (index !== -1) {
      state.orders[index].needsReview = false;
    }
    if (state.order.id === orderId) {
      state.order.needsReview = false;
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
