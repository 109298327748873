<template>
  <v-app-bar
    app
    flat
    :class="{ 'block-loading': blockLoading }"
    class="greyLight"
  >
    <div
      :style="{ 'max-width': '110px' }"
      style="max-height: 75px"
    >
      <img
        :src="logo"
        style="max-height: 80px; max-width: 100%"
        class="hover-pointer"
        @click="logoClick"
      />
    </div>
    <v-spacer />
    <mcs-btn @click="createAccount">Create Account</mcs-btn>
  </v-app-bar>
</template>

<script>
import { ROUTES } from '../../utils/constants';

export default {
  name: 'UnauthorizedHeader',

  components: {},

  mixins: [],

  data() {
    return {
      profileLink: ROUTES.PROFILE.PATH
    };
  },

  computed: {
    logo() {
      return 'https://res.cloudinary.com/mycreativeshop/image/upload/f_webp,w_350/v1/public/mcs-logo-dark';
    },

    blockLoading() {
      return this.$store.state.config.blockUILoading;
    }
  },

  methods: {
    logoClick() {
      window.location = process.env.VUE_APP_WEB_URL;
    },
    createAccount() {
      window.location = `${process.env.VUE_APP_WEB_URL}account/login?returnUrl=${window.location.href}`;
    }
  }
};
</script>
